<template>
  <div :id="domId" :class="{'show-on-artist-detail-mode': artistDetailMode}">

    <div v-if="loading" class="lazy-img-spinner w-100 text-center paddingY">
      <b-spinner type="grow" label="Loading..."></b-spinner>
    </div>

    <section v-else v-show="(artistDetailMode || !parentDetailMode) && !detailMode && name">

      <h1 v-if="listMode !== 'expandedList'"><strong>{{name}}</strong></h1>
      <transition name="fade">
        <div>
          <div
              v-if="listMode !== 'expandedList'"
              v-html="description"
              class="description" ></div>

          <Category
              v-for="(category, index) in categories"
              :key="category.id"
              :category="category"
              :categoryIndex="index"
              :parentId="domId"/>
        </div>
      </transition>

    </section>
    <transition name="fade">
      <ObjectDetail
          v-show="detailMode"
          :object="currentObject"
          :parentId="domId"/>
    </transition>

  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';
import Category from './Category';
import ObjectDetail from './ObjectDetail'
import {
  getVueInstance,
  hideContentForDetailMode,
  trackPageView,
  activateTermLinks,
  activateObjectLinks
} from "../helpers/AppHelpers";
import {EventBus} from "../main";

export default {
  name:'ArtistDetail',
  props: ['artistBasicInfo', 'groupByCategory', 'categoriesFilter', 'tagsFilter', 'mode', 'listMode', 'parentDetailMode', 'parentId'],
  components: {
    Category,
    ObjectDetail,
    'b-spinner': BSpinner
  },
  data () {
    return {
      artistInfo: {},
      detailMode: false,
      artistDetailMode: false,
      currentObject: {},
      loading: true,
      original_title: ''
    }
  },
  computed: {
    categories (){
      if(this.groupByCategory === 'true')
        return this.artistInfo.data.filter(category => category.categoryName !== '')
      else
        return [{
          categoryName: '',
          objects: this.artistInfo.data
        }]
    },
    name (){
      return this.artistInfo.artist ? this.artistInfo.artist.name : '';
    },
    description (){
      return this.artistInfo.artist ? this.artistInfo.artist.description : '';
    },
    domId (){
      // Generate a unique ID since there might be more than one ArtistDetail component on the page
      return `artist-detail-${this.name.replace(' ', '-') + Math.random().toString(36).substr(2, 9)}`;
    }
  },
  watch:{
    currentObject: function(object){
      if(object.updateHistory){
        history.pushState(null, null, `${location.href.split('/object/')[0]}/object/${object.slug}`);
        document.title = `${object.artist.name}, ${object.title}`;
        trackPageView(this, `${object.artist.name}, ${object.title}`); // Custom pageview event because Google Analytics doesn't detect this as a new page
      }
    }
  },
  async created(){
    await this.getPageContentFromParameters();

    this.original_title = document.title;

    // Necessary manipulation for vanity URLs when a TermDetail or an ObjectDetailLight overlay closes
    EventBus.$on('simple-overlay-closed', this.simpleOverlayClosed);
  },
  beforeDestroy() {
    EventBus.$off('simple-overlay-closed', this.simpleOverlayClosed);
  },
  methods: {
    // Performs some actions when the detailMode is updated, according to different parameters
    updateDetailMode: function(detailMode, updateHistory = true){
      this.detailMode = detailMode;

      // When setting the detail mode to false
      if(!detailMode){
        // Update history if the corresponding parameter is set to true
        // Useful to avoid adding an extra history entry on back navigation
        if(updateHistory)
          history.pushState(null, null, location.href.split('/object')[0]);

        // If the ArtistDetail component is not on expanded list mode, update the title and track a view on the analytics
        document.title = `${this.listMode === 'expandedList' ? this.original_title : this.name}`;
        trackPageView(this, `${this.listMode === 'expandedList' ? this.original_title : this.name}`); // Custom pageview event because Google Analytics doesn't detect this as a new page
      }

      // Wait for the DOM to be updated before adjusting the visibility of some elements
      this.$nextTick(function () {
        hideContentForDetailMode(detailMode, 'object');
        this.updateObjectsListMode(detailMode);
      });
    },
    getPageContentFromParameters: async function(){

      if(this.listMode !== 'expandedList' && this.mode !== 'elementDetail' &&  this.mode !== 'objectDetail' && !location.href.includes('detail'))
        return;

      this.loading = true;
      this.artistInfo = {};
      const url = new URL(location.href);

      let slug = url.searchParams.get("detail") ?
                url.searchParams.get("detail") :
                this.artistBasicInfo.slug ?
                this.artistBasicInfo.slug :
                null;

      let objectSlug = '';

      if(slug === null || slug === undefined)
        slug = location.href.split('detail/')[1];

      if(slug === null || slug === undefined)
        return;

      history.replaceState(null, null, decodeURIComponent(location.href.replace('?detail=', '/detail/')));

      if(slug.includes('object')){
        let splitSlug = slug.split('/object/')
        slug = splitSlug[0];
        objectSlug = splitSlug[1];
      }
      else if(url.searchParams.get("object")){
        objectSlug = url.searchParams.get("object");
        history.replaceState(null, null, location.href.replace('?object=', '/object/'));
      }
      else if(location.href.includes('/object/')){
        objectSlug = location.href.split('/object/')[1];
      }
      else {
        objectSlug = '';
        this.updateDetailMode(false, false);
      }

      if(slug === 'collection'){
        this.artistDetailMode = true;
        this.artistInfo = this.artistBasicInfo;
      }
      else{
        // Get the artist from the endpoint
        let api = `${process.env.VUE_APP_HOST}/.rest/gallery/v1/artist/slug`;

        // Populate the query with the parameters
        let parameters = [];

        if (slug)
          parameters.push(`slug=${slug}`);

        if (this.groupByCategory)
          parameters.push(`groupByCategory=${this.groupByCategory}`);

        if (this.categoriesFilter)
          parameters.push(`categories=${this.categoriesFilter}`);

        if (this.tagsFilter)
          parameters.push(`tags=${this.tagsFilter}`);

        if(parameters)
          parameters = `?${parameters.join('&')}`;

        // Run the query
        let artistResponse = await fetch(api + parameters);

        this.artistInfo = await artistResponse.json();
      }

      activateTermLinks();
      activateObjectLinks();

      this.loading = false;

      if(objectSlug)
        this.selectObject(objectSlug);
      else {
        window.scrollTo(0, 0); // Scroll to the top of the page

        if(this.listMode !== 'expandedList'){
          document.title = this.name;
          trackPageView(this, `${this.name}`); // Custom pageview event because Google Analytics doesn't detect this as a new page
        }
      }

      // Activate or deactivate the artist detail mode
      // Activate if the url refers to this artist
      const artistSlug = slug;
      if(location.href.includes(artistSlug)){
        this.artistDetailMode = true;
        this.updateObjectsListMode(true);
        hideContentForDetailMode(true, 'artist');
      }
      // Deactivate if the url does not refer to this artist and neither to an object
      else if(objectSlug === '') {
        // Keep the artistDetailMode flag alive if the component is on expandedList mode,
        // therefore needing visibility even if the url doesn't explicitly refer to it
        if(this.listMode !== 'expandedList')
          this.artistDetailMode = false;

        this.updateObjectsListMode(false);
        hideContentForDetailMode(false, 'artist');
        hideContentForDetailMode(false, 'object');
      }
    },
    // Opens the Object Detail modal
    openObjectDetailComponent: function(){
      setTimeout(() => {document.getElementById('object-detail' + this.currentObject.id).scrollIntoView({behavior: "smooth"})}, 0);
      this.updateDetailMode(true);
      // Set app detail mode to toggle visibility of certain elements
      let app = getVueInstance('app');
      app.addLayer();

      activateTermLinks();
      activateObjectLinks();
    },
    // Closes the Object Detail modal
    closeObjectDetailComponent: function(clickedId = ''){
      this.updateDetailMode(false);
      // Set app detail mode to toggle visibility of certain elements
      let app = getVueInstance('app');
      app.removeLayer();

      if(clickedId){
        // Restore scroll position on objects list
        this.$nextTick(function () {
          let elem = document.querySelector('#' + clickedId);
          if(elem){
            let box = elem.getBoundingClientRect();
            window.scrollTo(0, box.top + window.pageYOffset);
          }
        });
      }
    },
    selectObject: function(objectSlug){
      this.$nextTick(function () {
        for(let categoryIndex in this.categories){
          let category = this.categories[categoryIndex];
          for(let objectIndex in category.objects){
            let object = category.objects[objectIndex];
            if(object.slug === objectSlug){
              let domId = `object_${object.id}`;

              this.currentObject = {
                updateHistory: false,
                category: category,
                index: parseInt(objectIndex),
                clickedId: domId, // Used to restore scroll position on objects list
                ...object
              };

              this.openObjectDetailComponent();

              document.title = `${object.artist.name}, ${object.title}`;
              trackPageView(this, `${object.artist.name}, ${object.title}`); // Custom pageview event because Google Analytics doesn't detect this as a new page

              return;
            }
          }
        }
      });
    },
    onPopState: function(){
      this.getPageContentFromParameters();
    },
    updateObjectsListMode: function(detailMode){
      // Notify the parent objects list of the activation/deactivation of detail mode
      // in order to manage elements visibility
      this.$nextTick(function () {
        let objectsList = getVueInstance(this.parentId);
        objectsList.detailMode = detailMode;
      });
    },
    simpleOverlayClosed: function(){
      // Necessary manipulation for vanity URLs when a TermDetail or an ObjectDetailLight overlay closes
      history.replaceState(null, null, decodeURIComponent(location.href.replace('?detail=', '/detail/').replace('?object=', '/object/')));
    }
  }
};
</script>
<style scoped lang="scss">
@import 'src/vars.scss';

.description{
  padding-bottom: $spacer;
  max-width: 67%;
}

@include media-breakpoint-down(md){
  .description{
    max-width: 100%;
  }
}
</style>