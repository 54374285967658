<template>
  <div class="Basic">

    <main>
      <EditableArea v-if="main" v-bind:content="main" id="mainArea"/>
    </main>

    <Footer/>

  </div>
</template>

<script>
import { EditableArea } from '../vue-editor';
import Footer from '../components/Footer';
import {trackPageView} from "../helpers/AppHelpers";

export default {
  name: 'Child',
  components: {
    EditableArea,
    Footer,
  },
  props: ['title', 'metadata', 'main', 'meta'],
  created(){
    // Change the page title on navigation with the one specified on the meta_title dialog property
    if(this.meta?.meta_title)
      document.title = this.meta?.meta_title

    // GA Analytics tracking
    if(!location.href.includes('/detail/') && !location.href.includes('?detail=') && !location.href.includes('/object/') && !location.href.includes('?object=')){
      trackPageView(this, document.title);
    }
  }
};
</script>
