<template>
  <div class="carousel-root-wrapper">
    <div class="cool-lightbox-wrapper" v-if="enlarge == 'true'">
      <CoolLightBox
          :items="swiperSlides"
          :index="index"
          @close="onClose"
          @on-open="onOpen">
      </CoolLightBox>
    </div>

    <swiper class="swiper" ref="mySwiper" :options="swiperOption" >
      <swiper-slide v-for="(slide, imageIndex) in swiperSlides" :key="imageIndex" class="images-wrapper" :style="{width: slide.percent_width *100 + '%'}">
        <div v-lazyload>
          <img
              class="image lazy-img-fadein"
              @click="index = imageIndex"
              :data-url="slide.src"
              :src="slide.src"
              :srcset="slide.srcset"
              :sizes="slide.sizes"
              :style="{'max-height': swiperHeight}"
              :alt="slide.title">
        </div>

      </swiper-slide>
    </swiper>
  </div>

</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import {getLazyLoadImage, removeLazyLoadFromDuplicates} from "../helpers/AppHelpers";

function getHs(data) { //mapping the array of Dimensions(objects) in a list o heights(integers)
    return data.map(elem => elem.height)
}

function getMinH(data) {
  return Math.min(...getHs(data));
}

function calculatePicturesProportions(arrayPictures, screenWidth, screenHeight, maxHeight) {
  let picturesDimensions = []
  for (let item of arrayPictures) {
    let picture = {}
    // Get the images dimensions from the metadata or use the placeholder ones
    picture.width = parseFloat(item['image'].metadata?.width || 600)
    picture.height = parseFloat(item['image'].metadata?.height || 600)
    picture.percent_width = 0.8
    let aspectRatio = Number(picture.width*1.0/picture.height)
    if(Number(maxHeight)) {
      if(picture.height > maxHeight){
        picture.height = maxHeight;
        picture.width = maxHeight * aspectRatio;
      }
    }
    if(0.8 * screenWidth < picture.width) {
      picture.width = 0.8 * screenWidth
      picture.height = Number(picture.width/aspectRatio)
    } else if(0.8 * screenWidth > picture.width ) {
      let percent_width = Number(picture.width/(screenWidth))
      picture.percent_width = percent_width
    }
    picturesDimensions.push(picture)


  }
  maxHeight = getMinH(picturesDimensions)
  for (let [i, dim] of picturesDimensions.entries()) {
    let aspectRatio = Number(dim.width/dim.height)
    if(dim.height > maxHeight) {
      dim.height = maxHeight
      dim.width = dim.height * aspectRatio
      dim.percent_width = Number(dim.width/screenWidth)
      picturesDimensions[i] = dim;
    }
  }
  return picturesDimensions
}

function getDataSwiperSlides(carouselItems) {
  let response = []
  for(let item of carouselItems) {
    let lazySlide = getLazyLoadImage(item.image['@link']);
    lazySlide.title = item.title;
    response.push(lazySlide);
  }
  return response
}

export default {
  name: 'Carousel',
  props: ['carousel', 'height', 'enlarge'],
  title: 'Carousel',
  components: {
    swiper,
    swiperSlide,
    CoolLightBox
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    swiperSlides(){
      let result = []
      let swiperSlidesData = getDataSwiperSlides(this.carousel)
      let picturesDimensions = calculatePicturesProportions(this.carousel, this.$screen.width, this.$screen.height, this.height)
      for (let [i] of swiperSlidesData.entries()) {
        let newSlide = {
          ...swiperSlidesData[i],
          ...picturesDimensions[i]
        }
        result.push(newSlide)
      }
      return result
    }
  },
  watch:{
    swiperSlides: function(val){
      this.swiper.loopedSlides = val.length;
    }
  },
  mounted() {

    if(this.height && !isNaN(this.height) && !isNaN(parseFloat(this.height))) {
      this.swiperHeight = this.height + "px";
    }

    // Keep the same speed (pixel/millisecond) for each picture
    this.swiper.on('transitionStart', function() {

        if(this.realIndex){
          let picture = this.slides[this.realIndex];
          let speed = picture.clientWidth / 0.067; // Calculate the speed based on the picture width
          this.params.speed = speed;

          // Reset autoplay delay after first slide
          if(this.params.autoplay.delay > 0)
            this.params.autoplay.delay = 0;
        }
    });

    removeLazyLoadFromDuplicates();
    this.swiper.autoplay.start();
  },
  methods: {
    onOpen(indexItem) {
      this.indexItemOpened = indexItem;
      this.swiper.autoplay.stop();
    },
    onClose() {
      this.index = null;
      this.swiper.autoplay.start();
    }
  },
  data() {
    return {
      indexItemOpened: -1,
      swiperHeight: 'none',
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        speed:5000,
        loop:true,
        autoplay: {
          delay: 500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        directives: {
          swiper: directive
        }
      },
      index: null
    }
  }
}
</script>

<style lang="scss">
@import 'src/vars.scss';
.carousel-root-wrapper{
  margin-bottom: $spacer;
}

.swiper-wrapper{
  transition-timing-function: linear;
}

.images-wrapper .image {
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  height: auto;
}
</style>




