<template>
  <div>

    <b-link
        v-if="!inPreviewMode"
        v-on:click.ctrl="openInNewTab"
        v-on:click.meta="openInNewTab"
        v-on:click.exact="openDetailComponent"
        @click.prevent
        :href="href"
        class="block list no-background-image">
      <span class="underline">
        {{name}}
      </span>
      <p class="italic">{{fromDate}}<span v-if="toDate"> bis {{toDate}}</span></p>
    </b-link>

    <div
        v-if="inPreviewMode"
        v-on:click.ctrl="openInNewTab"
        v-on:click.meta="openInNewTab"
        v-on:click.exact="openDetailComponent"
        class="list link">
      <span class="underline">
        {{name}}
      </span>
      <p class="italic">{{fromDate}}<span v-if="toDate"> bis {{toDate}}</span></p>
    </div>

  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import {getVueInstance} from "@/helpers/AppHelpers";
import { inMgnlIframe, inEditorEdit } from "../vue-editor";

export default {
  name: 'EventsListItem',
  props: ['event'],
  components: {
    'b-link': BLink
  },
  computed:{
    id(){
      return this.event.id;
    },
    name(){
      return this.event.jcrName;
    },
    fromDate(){
      return this.event.fromDate ? this.getDateString(this.event.fromDate) : '';
    },
    toDate(){
      return this.event.toDate ? this.getDateString(this.event.toDate) : '';
    },
    href (){
      return `${location.origin}${location.pathname.replace('.html', '')}/detail/${this.event.slug}`
    },
    inPreviewMode(){
      return inMgnlIframe && !inEditorEdit;
    }
  },
  methods:{
    getDateString: function(date){
      const newDate = new Date(date);
      return newDate.toLocaleDateString('DE',{month: 'long', // "June"
        day: '2-digit', year: 'numeric'});
    },
    openDetailComponent: function (){
      let eventsListComponent = getVueInstance('events-list');
      let elementDetails = {
        updateHistory: true,
        ...this.event
      }

      eventsListComponent.currentElement = elementDetails;
      eventsListComponent.openElementDetailComponent();
    },
    openInNewTab: function(){
      window.open(this.href, '_blank');
    }
  }
};
</script>