<template>
  <div v-if="showSlider">
    <div class="cool-lightbox-wrapper" v-if="enlarge == 'true'">
      <CoolLightBox
          :items="swiperSlides"
          :index="index"
          @close="onClose"
          @on-open="onOpen">
      </CoolLightBox>
    </div>

    <div class="wrapper-slider-custom position-relative"
         @mouseover="mouseOverSlide"
         @mouseleave="mouseLeaveSlide">
      <span class="icon sc-icon icon-close"
            @click="closeSlider()"
      ></span>
      <swiper
          @ready="sliderMounted"
          @slide-change="slideChange"

          v-if="swiperSlides.length>0"  class="swiper wrapper-slider" ref="mySwiper" :options="swiperOption" :key="swiperCounter">

        <swiper-slide v-for="(slide, imageIndex) in swiperSlides" ref="slideComp"
                      :key="imageIndex"
                      class="slider-all-wrapper position-relative">

          <div class="row equal" v-lazyload>

              <div class="col-xs-6 col-in-slider background-image"
                   v-if="slide.screen=='desktop'"
                   :data-background="slide.src"
                   :data-key="imageIndex" ref="backgroundImage">
              </div>

              <div class="col-in-slider mobile-elem" v-if="slide.screen=='mobile'">

              <div v-lazyload class="images-wrapper">
                  <img
                      class="image lazy-img-fadein"
                      @click="index = imageIndex"
                      :data-url="slide.src"
                      :src="slide.src"
                      :srcset="slide.srcset"
                      :sizes="slide.sizes"
                      :alt="slide.title">
              </div>

            </div>
            <div class="col-xs-6 col-in-slider">
              <div class="wrapper-text position-relative" :style="{'min-height': swiperHeight}">
                <div class="inner-text-content" >
                    <h2><b>{{slide.title}}</b></h2>
                    <div class="text" v-html="slide.description">
                    </div>
                    <div class="read-more" :class="{'showOff': !slide.hasDestination }">
                      <span class="icon sc-icon icon-arrow-right"></span>
                      <div class="url" >
                        <router-link v-if="slide.destination_field=='internal'" :to="{path: slide.destination}" @click.native="updateViewKey">{{slide.calltoaction}}</router-link></div>
                        <a class="inner-link" v-if="slide.destination_field=='external'" :href="slide.destination"  target="_blank">{{slide.calltoaction}}</a>
                      </div>
                    </div>
              </div>
            </div>
          </div>

        </swiper-slide>
        <div class="swiper-button-prev icon sc-icon icon-arrow-left" slot="button-prev"
          :class="{'showOff': swiperSlides.length == 1}"></div>
        <div class="swiper-button-next icon sc-icon icon-arrow-right" slot="button-next"
          :class="{'showOff': swiperSlides.length == 1}"></div>
      </swiper>
    </div>

  </div>

</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { swiper, swiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import {getLazyLoadImage, updateViewKey} from "../helpers/AppHelpers";


export default {
  name: 'Slider',
  props: ['slider', 'height', 'enlarge'],
  title: 'Slider',
  components: {
    swiper,
    swiperSlide,
    CoolLightBox
  },
  computed: {

    swiper() {
        return this.$refs.mySwiper?.swiper
    },

    swiperSlides() {
      let slides = []
      if(this.destinationArrayCompleted) {
        for(let [i, item] of Object.entries(this.slider)) {

          if(!item.description) {
            item.description='';
          }

          const image = getLazyLoadImage(item.image['@link']);

          const screen = this.$screen.width >= 768 ? 'desktop' : 'mobile';

          const calltoaction = item.calltoaction || 'Read more';

          if(item.visibility == "false") {
            slides.push({ screen, title: item.title, src: image.src, srcset: image.srcset, sizes: image.sizes, calltoaction: calltoaction, visibility: item.visibility, description: item.description, hasDestination: this.destinationArray[i].hasDestination, destination: this.destinationArray[i].destination, destination_field: this.destinationArray[i].destination_field});
          }
        }
      }
      return slides
    }
  },
  created: function() {
    window.addEventListener('resize', this.onResize)
  },
  mounted: async function() {

    if(this.height && !isNaN(this.height) && !isNaN(parseFloat(this.height)) && this.$screen.width >= 768) {
      this.swiperHeight = this.height + "px";
    } else if(this.$screen.width < 768) {
      this.swiperHeight = "auto";
    }
    this.sliderId = this.slider[0]['@id']
    if(this.$store.getters.checkSlider(this.sliderId)) {
      if(!this.$store.getters.checkStatusSlider(this.sliderId)){
        this.showSlider = false;
      }

    } else {
      this.$store.commit("addSlider", this.sliderId)
    }

    for(let item of this.slider) {
      let hasDestination = false;
      let destination = ""
      let destination_field = ""

      if(item.destination?.field && item.destination.field == "internal" && item.destination?.destination) {
        // If it's an internal link
        // Get page name from destination id, and use it as :to value
        let params = `?@jcr:uuid=${item.destination.destination}`;
        let query = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGENAV + params;
        const pagenavResponse = await fetch(query);
        const pageInfo = await pagenavResponse.json();
        if (pageInfo.results.length)
          destination = pageInfo.results[0]['@path'];
        else hasDestination = false
        destination_field = "internal"
        hasDestination = true;
      } else if(item.destination?.field =="external" && item.destination?.url !="") {
        destination = item.destination.url
        hasDestination = true;
        destination_field = "external"
      }

      this.destinationArray.push({hasDestination,
        destination, destination_field})
    }

    this.destinationArrayCompleted = true
  },
  methods: {
    mouseOverSlide() {
      this.swiper.autoplay?.stop();
    },
    mouseLeaveSlide() {
      this.swiper.autoplay?.start();
    },
    sliderMounted() {
      if(this.$screen.width < 768) {
        this.resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {

            let swiper_wrapper = document.querySelectorAll(".swiper-wrapper")[0]
            let rows = this.$refs.mySwiper.$el.querySelectorAll(".row")

            swiper_wrapper.style.height = rows[1].clientHeight + "px";

            this.firstSlideHeightMobile = entry.target.style.height;

          }
        });
        // Only observe the second box
        this.resizeObserver.observe(this.$refs.mySwiper.$el.querySelectorAll(".row")[1]);
      }
    },
    onResize() {

      if (window.innerWidth != this.windowWidth) {

        // Update the window width for next time
        this.windowWidth = window.innerWidth;

        this.swiperCounter++;

        if(this.height && !isNaN(this.height) && !isNaN(parseFloat(this.height)) && this.$screen.width >= 768) {
          this.swiperHeight = this.height + "px";

        }
        else if(this.$screen.width < 768) {
          //Resize Observer for slider height on mobile hack overflow content
          this.swiperHeight = "auto";
        }

      }
    } ,
    slideChange() {
      if(!this.firstSlide && this.$screen.width < 768 && this.resizeObserver != null) {
        this.firstSlide = true;
      }
      if(this.$screen.width >= 768) {

        //first image dissapears hack
        let elem = this.$refs.slideComp[this.$refs.slideComp.length-this.swiperSlides.length].$el;

        let elem_data_key = elem.querySelectorAll('[data-key="0"]')
        if(elem_data_key.length != 0) {
          if(elem.classList.contains('swiper-slide-duplicate-next')) {
            let array_elem = this.$refs.mySwiper.$el.querySelectorAll('.swiper-wrapper');
            elem = array_elem[0]
            elem_data_key = elem.querySelectorAll('[data-key="0"]')

            if(elem_data_key.length > 0) {
              elem_data_key[elem_data_key.length-1].classList.add("already-loaded")
              elem_data_key[elem_data_key.length-1].style.backgroundImage = "url('" + this.swiperSlides[0].src + "')";
            }

            return;
          } else if(elem.classList.contains('swiper-slide-duplicate-active')) {
            let array_elem = this.$refs.mySwiper.$el.querySelectorAll('.swiper-wrapper');
            elem = array_elem[0]
            elem_data_key = elem.querySelectorAll('[data-key="' + (this.swiperSlides.length - 1) +'"]')

            elem_data_key[0].classList.add("already-loaded")
            elem_data_key[0].style.backgroundImage = "url('" + this.swiperSlides[this.swiperSlides.length-1].src + "')";

          }
        }
      }
    },
    backgroundImageMethod(url) {
      this.backgroundImageUrl = url
    },
    closeSlider(){
      this.$store.commit("turnOffSlider", this.sliderId)
      this.showSlider = false;
    },
    onOpen(indexItem) {
      this.indexItemOpened = indexItem;
      this.swiper.autoplay.stop();
    },
    onClose() {
      this.index = null;
      this.swiper.autoplay.start();
    },
    updateViewKey: function(){
      updateViewKey();
    }
  },
  data() {
    return {
      swiperContainerHeightMobile: "auto",
      sliderCounter: 0,
      firstSlideHeightMobile: null,
      firstSlide: false,
      resizeObserver: null,
      swiperObserver: null,
      pageLoaded: false,
      swiperHeight: "400px",
      swiperCounter: 0,
      image: "",
      imageUrl: "",
      destinationArray: [],
      destinationArrayCompleted: false,
      indexItemOpened: -1,
      swiperOption: {
        slidesPerView: '1',
        spaceBetween: 30,
        preloadImages: true,
        updateOnImagesReady: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: false
        },
        autoHeight: true,
        directives: {
          swiper: directive
        },
      },
      index: null,
      sliderId: null,
      showSlider: true,
      windowWidth: window.innerWidth
    }
  }
}
</script>

<style scoped>

img, .background-image {
  opacity: 0;
}

.background-image.already-loaded {
  opacity: 1;
}

img.loaded, .background-image.loaded  {
  opacity: 1;
  animation: fadein 1s ease-in;
}

.swiper-slide-duplicate img {
  opacity: 1 !important;
  animation: fadein 1s ease-in;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.showOff {
  display: none!important;
}

.read-more span, .read-more div.url {
  display: inline-block;
  vertical-align: middle;
}

.read-more div.url {
  display: inline-block;
  line-height: 1.55;
  margin-left: 1.3rem;
  cursor: pointer;
}

.icon-close {
  position: absolute;
  top: 20px;
  z-index: 2;
  cursor: pointer;
}

.swiper-slide {
  width: 100%!important;
}

.swiper-button-prev, .swiper-button-next {
  display: none;
}

.swiper-slide-prev, .swiper-slide-next {
  visibility:hidden;
}

.images-wrapper .image {
  max-width: 80%;
  margin: auto;
}

.wrapper-text {
  padding-top: 1.25rem;
  display: flex;
  align-items: stretch;
}

.inner-text-content {
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: left;
}

.inner-text-content > h2, .inner-text-content > div {
  align-self: flex-start;
}

.images-wrapper {
  display: block;
}

.equal > [class*='col-'] {
  display: block;
}

@media only screen and (max-width: 767px) {
  .mobile-elem {
    background: none !important;
  }
}

@media only screen and (min-width: 768px) {

  .wrapper-text {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
  }

  .wrapper-slider{
    padding: 0rem;
  }

  .swiper-slide {
    margin-left: -10px;
  }

  .background-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .images-wrapper {
    display: none;
  }
  .icon-close {
    right: 0px;
  }

  .wrapper-text {
    width: 80%;
    margin: auto 0px;
  }
  .slider-all-wrapper, .images-wrapper{
    margin: 0 auto;
  }

  .images-wrapper image {
    display: inline-block;
  }

  .images-wrapper .image {
    cursor: pointer;
    max-width: 100%;
    width: 80%;
    height: auto;
    margin: auto;
  }

  .equal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;

  }
  .row.equal {
    margin-right: 0px;
    margin-left: 0px;
  }
  .equal > [class*='col-'] {
    flex: 1;
    flex-direction: column;
    width: calc(50% - 30px);
    overflow: hidden;
  }

  .wrapper-text {
    padding-left: 30px;
  }

  .row .col-in-slider:first-of-type {
    padding-right: 10px;
  }

  .row .col-in-slider:last-of-type {
    padding-left: 10px;
  }
  .swiper-button-prev, .swiper-button-next {
    display: block;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    content: "";
  }
  .swiper-button-prev {
    left: 30px;
    color: black;
  }

  .swiper-button-next {
    right: 30px;
    color: black;
  }
}

.centered-text-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.min-swiper-height {
  min-height: 400px;
}

.force-height-container {

}

@media(max-width: 767px) {
  .min-swiper-height {
    min-height: 200px;
  }
}

@media (max-width:767px) {
  .swiper-wrapper {
    overflow-x: hidden;
  }

  .swiper-slide {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

</style>




