<template>
  <EditablePage
    v-if="content"
    v-bind:content="content"
    v-bind:config="config"
    v-bind:templateDefinitions="templateDefinitions"
  />
</template>

<script>
import config from "../magnolia.config";
import { EditablePage, inEditorEdit } from "../vue-editor";
import {getBasename, getHostBasedBasename, getRestResource, getVueInstance} from "./AppHelpers";

const getContentUrl = () => {
  let nodeName = getBasename();
  let isRootPage = nodeName === '/';
  localStorage.isRootPage = isRootPage;

  let path = '';

  if(isRootPage){
    nodeName = getHostBasedBasename();
    path = nodeName + '/home'
  }
  else{
    path =
      nodeName +
      window.location.pathname.replace(
        new RegExp("(.*" + nodeName + "|.html)", "g"),
        ""
      );
  }

  return process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGES + path;
};

export default {
  name: "PageLoader",
  components: {
    EditablePage
  },
  data: function() {
    return {
      content: undefined,
      config,
      templateDefinitions: {}
    };
  },
  methods: {
    async loadPage() {
      let app = getVueInstance('app');

      let contentResponse = await fetch(getContentUrl());
      let content = null;

      // If we have a 404, get the 404 page content with the current basename
      if(contentResponse.status === 404){
        localStorage.is404 = true;

        content = await getRestResource('page_content', '/404');
      }
      else{
        localStorage.is404 = false;
        content = await contentResponse.json();
      }

      if (inEditorEdit) {
        const templateDefinitionsResponse = await fetch(
            process.env.VUE_APP_HOST + process.env.VUE_APP_REST_TEMPLATE_DEFINITION +
            content["mgnl:template"]
        );
        const templateDefinitions = await templateDefinitionsResponse.json();

        this.templateDefinitions = templateDefinitions;
      }

      // Reset app detail mode on each page load
      app.openLayers = 0;

      this.content = content;
    }
  },
  mounted() {
    this.loadPage();
  },
  updated() {
    if (inEditorEdit) window.parent.mgnlRefresh();
  }
};
</script>
