<template>
  <transition name="fade">
    <b-form v-if="visible" id="form">

      <transition name="fade">
        <div v-if="submitted" class="submitted">
          <h2><strong>Vielen Dank für Ihr Interesse.</strong></h2>
          <h2>Wir setzen uns bald mit ihnen in Kontakt.</h2>
        </div>
      </transition>

      <h2><b>Anfrage zum Werk</b></h2>
      <Button
          :text="''"
          :variant="'transparent'"
          :onClick="cancel"
          class="close-button icon icon-close"/>
      <b-row>

        <b-col md="6">

          <b-row>
            <b-col md="6">
              <b-form-group
                  label="Vorname"
                  label-for="name">
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Vorname"
                    v-model="name"
                    :state="validation.name"
                    @change="validateForm('name')">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Nachname"
                  label-for="name">
                <b-form-input
                    id="surname"
                    type="text"
                    placeholder="Nachname"
                    v-model="surname"
                    :state="validation.surname"
                    @change="validateForm('surname')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                  label="E-Mail"
                  label-for="email">
                <b-form-input
                    id="email"
                    type="email"
                    placeholder="E-Mail"
                    v-model="email"
                    :state="validation.email"
                    @change="validateForm('email')">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Telefon (fakultativ)"
                  label-for="telephone">
                <b-form-input
                    id="telephone"
                    type="tel"
                    placeholder="Telefon"
                    v-model="telephone"
                    :state="validation.telephone"
                    @change="validateForm('telephone')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

        </b-col>

        <b-col md="6">
          <b-form-group
              label="Mitteilung"
              label-for="message">
            <b-form-textarea
                id="message"
                placeholder="Mitteilung"
                rows="5"
                max-rows="5"
                v-model="message"
                :state="validation.message"
                @change="validateForm('message')">
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="text-danger error" v-if="validation.showError">
        {{error}}
      </div>

      <div class="form-buttons">

        <b-button variant="primary" type="button" @click="submit" :disabled="isProcessing">
          <b-spinner small type='grow' v-if="isProcessing"></b-spinner>
          Senden
        </b-button>

        <Button :text="'Abbrechen'" :type="'button'" :variant="'transparent'" :onClick="cancel"/>
      </div>

    </b-form>
  </transition>


</template>

<script>
import {BForm, BFormGroup, BFormInput, BFormTextarea, BSpinner, BButton} from 'bootstrap-vue';
import Button from "@/components/Button";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import {getBasename} from "../helpers/AppHelpers";
Vue.use(VueReCaptcha, { siteKey: "6Lc2vf8ZAAAAAJO-r3a2mKhXSO1lLLtTo8e1bzcV" });

export default {
  name: 'FormComponent',
  props:['objectId'],
  components: {
    'b-form': BForm,
    'b-form-input': BFormInput,
    'b-form-group': BFormGroup,
    'b-form-textarea': BFormTextarea,
    'b-spinner': BSpinner,
    'b-button': BButton,
    Button
  },
  data(){
    return{
      name: '',
      surname: '',
      email: '',
      telephone: '',
      message: '',
      visible: false,
      submitted: false,
      isProcessing: false,
      errors: ['Bitte füllen sie alle Angaben korrekt aus.', 'Sorry, beim Absenden der Anfrage ist etwas schief gelaufen.'],
      error: '',
      validation:{
        name: null,
        surname: null,
        email: null,
        telephone: null,
        message: null,
        showError: false
      }
    }
  },
  mounted(){
    this.error = this.errors[0];
  },
  methods:{
    closeForm (){
      this.visible = false;
    },
    openForm (){
      this.visible = true;
      setTimeout(() => {document.getElementById('form').scrollIntoView({behavior: "smooth"})}, 0);
    },
    cancel (){
      // Reset form to initial status
      this.name = '';
      this.surname = '';
      this.email = '';
      this.telephone = '';
      this.message = '';

      this.validation.name = null;
      this.validation.surname = null;
      this.validation.email = null;
      this.validation.telephone = null;
      this.validation.message = null;
      this.validation.showError = false;

      this.submitted = false;

      this.closeForm();
    },
    submit (){
      this.isProcessing = true;
      if(this.validateForm('')){
        this.$recaptcha("submit").then(async (token) => {
          const formData = new FormData();

          const body = document.querySelector('body');
          const csrf = body.dataset.csrf;

          const site = getBasename().replaceAll('/', '');

          formData.append('csrf', csrf);
          formData.append('token', token);
          formData.append('name', this.name);
          formData.append('surname', this.surname);
          formData.append('email', this.email);
          formData.append('telephone', this.telephone);
          formData.append('message', this.message);
          formData.append('site', site);
          formData.append('objectId', this.objectId);
          formData.append('link', location.href);

          // Send form to submit endpoint
          let api = `${process.env.VUE_APP_HOST}/.rest/gallery/v1/submit-form`;

          let formResponse = await fetch(api, {
            method: 'POST',
            body: formData
          }).then((response) => {

            // Throw an error (that will be detected by the catch) if something went wrong with the request
            if (response.status >= 400 && response.status < 600) {
              throw new Error("Bad response from server");
            }

            return response;
          }).catch(() => {
            // Show error message on the form
            this.error = this.errors[1];
            this.validation.showError = true;
            this.isProcessing = false;
            return;
          });

          if(formResponse){
            let submitResult = await formResponse.json();

            if (submitResult.success){
              this.submitted = true;
            }
            else{
              // If the request worked but the operation on the server was unsuccessful
              // Show error message on the form
              this.error = this.errors[1];
              this.validation.showError = true;
            }
            this.isProcessing = false;
          }
        });
      }
      else
        this.isProcessing = false;

    },
    validateForm(field = ''){
      // Reset error message to the validation one
      this.error = this.errors[0];

      const emailRe = /\S+@\S+\.\S+/;
      const telRe = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

      // If the validation only targets a specific field, only validate that one
      switch (field){
        case 'name':
          this.validation.name = this.validation.name = this.name !== '';
          break;
        case 'surname':
          this.validation.surname = this.validation.surname = this.surname !== '';
          break;
        case 'email':
          this.validation.email = this.email !== '' ? emailRe.test(this.email) : null;
          break;
        case 'telephone':
          this.validation.telephone = this.telephone !== '' ? telRe.test(this.telephone) : null;
          break;
        case 'message':
          this.validation.message = this.message !== '';
          break;
        default:
          this.validation.email = emailRe.test(this.email);

          this.validation.telephone = this.telephone !== '' ? telRe.test(this.telephone) : true;

          this.validation.name = this.name !== '';
          this.validation.surname = this.surname !== '';
          this.validation.message = this.message !== '';

          break;
      }

      let valid = false;

      // If the validation only targets a specific field, only validate that one
      if(field === '')
        valid = this.validation.name && this.validation.surname && this.validation.email && this.validation.telephone && this.validation.message;
      else
        valid = this.validation[field];

      this.$nextTick(function () {
        this.validation.showError = !valid || document.querySelector('.is-invalid') !== null;
      });

      return valid;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'src/vars.scss';
form{
  background-color: #B9E3E6;
  margin: -$spacer;
  margin-top:0;
  margin-bottom:0;
  padding: $spacer;
  position:relative;

  .form-group{
    margin-top: $spacer;
    margin-bottom: 0;

    label{
      font-weight:bold;
    }

    .form-control{
      height:rem(40px);

      &.is-invalid{
        border-width: 2px;
      }
    }

    textarea.form-control{
      padding: rem(4px) rem(10px);
      min-height: 159px;
    }

    input.form-control{
      padding: 0 rem(10px);
    }
  }

  .form-buttons{
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    padding-top:$spacer;

    .spinner-grow{
      vertical-align:middle;
      margin-top:-4px;
      margin-left:-$spacer/4;
      margin-right:$spacer/4;
    }

    .btn-link{
      padding:0;
      padding-top:5px;
      padding-bottom:5px;
      flex:1;
      max-width:rem(180px);
    }
  }

  .error{
    opacity:0.6;
    padding-top:0.5rem;
  }

  .close-button{
    height:1rem + rem(30px);
    position:absolute;
    right:$spacer;
    top:$spacer - rem(10px);
    padding:0;
    margin:0;
    z-index:3;
  }

  .submitted{
    position:absolute;
    height:100%;
    width:100%;
    padding:$spacer;
    background-color:$green;
    top:0;
    left:0;
    z-index:2;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;

    h2{
      text-align:center;
    }
  }
}

@include media-breakpoint-down(md) {
  form{
    margin: -$spacer/2;
    margin-top:0;
    margin-bottom:0;
    padding: $spacer/2;

    .form-group{
      margin-top: $spacer/4;

      .form-control{
        font-size: inherit;
      }
    }

    .form-buttons{
      padding-top:$spacer/2;
    }

    .close-button{
      right:$spacer/2;
      top:$spacer/2 - rem(7px);
      padding:0;
      margin:0;
      z-index:3;
    }
  }
}
</style>