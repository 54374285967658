<template>
  <div id="events-list" class="show-on-detail-mode">
    <transition name="fade">
      <section v-if="mode === 'list'">
        <b-row>

          <transition name="fade">
            <b-col sm="12" md="6" v-if="futureEvents.length > 0">
              <h2><strong>Aktuelle</strong></h2>
              <EventsListItem
                  v-for="event in futureEvents"
                  :key="event.id"
                  :event="event"/>
            </b-col>
          </transition>

          <transition name="fade">
            <b-col sm="12" md="6" v-if="pastEvents.length > 0">
              <h2><strong>Vergangene</strong></h2>
              <EventsListItem
                  v-for="event in pastEvents"
                  :key="event.id"
                  :event="event"/>
            </b-col>
          </transition>

        </b-row>
      </section>
    </transition>

    <EventDetail
        v-show="mode === 'elementDetail' || mode === 'objectDetail'"
        :eventBasicInfo="currentElement"
        :mode="mode"
        ref="eventDetail"/>

  </div>
</template>

<script>
import EventsListItem from './EventsListItem';
import EventDetail from './EventDetail';
import { EventBus } from '../main';

export default {
  name: 'EventsList',
  props: ['eventDetailPage'],
  components: {
    EventsListItem,
    EventDetail
  },
  data () {
    return {
      pastEvents: [],
      futureEvents: [],
      mode: 'list',
      currentElement: {}
    }
  },
  watch:{
    currentElement: function(object){
      if(object.updateHistory)
        history.pushState(null, null, `${location.origin}${location.pathname.replace('.html', '')}?detail=${object.slug}`);

      this.$nextTick(function () {
        this.$refs.eventDetail.getPageContentFromParameters();
      });
    },

  },
  async created(){

    this.setMode();

    let api = `${process.env.VUE_APP_HOST}/.rest/events/v1/events`;

    // Run the query
    let eventsResponse = await fetch(api);

    let events = await eventsResponse.json();

    this.pastEvents = events.pastEvents ? events.pastEvents : [];
    this.futureEvents = events.futureEvents ? events.futureEvents : [];

    this.registerPopState();

    EventBus.$on('simple-overlay-opened', this.unregisterPopState);
    EventBus.$on('all-simple-overlays-closed', this.registerPopState);
  },
  methods: {
    // Opens the Element Detail modal
    openElementDetailComponent: function(){
      this.mode = 'elementDetail';
    },
    onPopState: function(){
      this.setMode();
      if(this.mode !== 'list')
        this.$refs.eventDetail ? this.$refs.eventDetail.onPopState() : false;
    },
    setMode: function(){
      let url = location.href;

      let mode = 'list'

      if(url.includes('/object/'))
        mode = 'objectDetail'
      else if(url.includes('/detail/') || url.includes('detail='))
        mode = 'elementDetail'

      this.mode = mode;

      return mode;
    },
    registerPopState: function(){
      window.addEventListener('popstate', this.onPopState);
    },
    unregisterPopState: function(){
      window.removeEventListener('popstate', this.onPopState);
    }
  }
};
</script>