import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import VueGtag from "vue-gtag";
import App from './App.vue';
import PageLoader from './helpers/PageLoader.vue';
import {getVueInstance, urlWithIdRedirect, getBasename, trackPageView, updateViewKey} from "./helpers/AppHelpers";
import './style.scss';
import './fonts/icons/style.css';
import './fonts/open_sans/style.scss';
import LazyLoad from "./directives/LazyLoad";

Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.config.silent = true;

// Detect browser navigation. used to enable browser navigation in
// conjunction with router-view viewKey system
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

Vue.directive("lazyload", LazyLoad);
Vue.use(VueRouter);

import { LayoutPlugin, NavbarPlugin } from 'bootstrap-vue';
Vue.use(LayoutPlugin);
Vue.use(NavbarPlugin);

Vue.use(Vuex)
/* The behaviour of the slider: if the user wants he can hidden the slider
from the page. I receive the first id
from the first slide (this solution is temporary) and store it as a "slider id".
   When the page is loaded first I check if the id of the carousel exist in store(checkSlider method)
   and check the status to see if I'm going to show the slider to the user(checkStatusSlider)
   and if the carousel doesn't exist in the store  I call the addSlider. I made the store to have persistent data
   for all sliders in the website.

   */
function checkSlider(objIds, id) {
    let found = false;
    let arrayIds = Object.keys(objIds)
    for(let prop of arrayIds){
        if(prop == id)
            found = true;
    }
    return found

}

export const store = new Vuex.Store({
    state: {
        showSliders: {},
        historySearch: ""
    },
    mutations: {
        addSearchHistory(state, input) {
            state.historySearch = input;
        },
        addSlider(state, sliderId) {

            if(!checkSlider(state.showSliders, sliderId)) {
                state.showSliders[sliderId] = true
            }
        },
        turnOffSlider(state, sliderId) {
            if(state.showSliders[sliderId]) {
                state.showSliders[sliderId] = false
            }
        },

    },
    getters: {
        getSearchHistory: (state) => () => {
            return state.historySearch;
        },
        checkSlider: (state) => (sliderId) => {
            return checkSlider(state.showSliders, sliderId);
        },
        checkStatusSlider: (state) => (sliderId) => {
            return state.showSliders[sliderId]
        }
    }
})

// Used for cross-component event communication
export const EventBus = new Vue();

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        { path: '*', component: PageLoader },
        { path: '*/detail/*', redirect: to => {
            return urlWithIdRedirect(to);
        }},
        { path: '/kuenstler/*', redirect: to => {
            return to.path.replace('/kuenstler/', '/galerie/kuenstler?detail=');
        }},
        { path: '/(galerie|picasso|mourlot)/home', redirect: '/'}
    ],
});

Vue.use(VueGtag, {
    bootstrap: false,
    onReady(){
        localStorage.isTrackingActive = 'true';

        // Take care of any trackings on our backlog that were requested when GA wasn't ready yet
        let missedTrackings = localStorage.missedTrackings ? JSON.parse(localStorage.missedTrackings) : [];

        missedTrackings.forEach(title => {
            trackPageView(this, title);
        })

        localStorage.missedTrackings = '';
    }
});

async function checkIsSearchPage(path) {
    const basename = getBasename();

    const pagenavResponse = await fetch(
        process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGENAV + basename
    );
    const pagenav = await pagenavResponse.json();
    let searchPage = ""
    if(pagenav['searchPage'])
        searchPage = pagenav['searchPage']['@path']
    if(searchPage == path) return true

    return false
}

router.beforeEach((to, from, next) => {
    // Used in the TermDetail and ObjectDetailLight components
    // Set the $lastOverlay instance property to an empty array in any case if it's not
    // a back navigation, or only when the back navigation is not happening between virtual pages
    if(!window.popStateDetected || (!decodeURIComponent(to.fullPath).includes('/object/') && !decodeURIComponent(to.fullPath).includes('?object') && !decodeURIComponent(to.fullPath).includes('/detail/') && !decodeURIComponent(to.fullPath).includes('?detail'))){
        Vue.prototype.$lastOverlay = [];
    }

    if(to.path) {
        if(from.path) {
            if(!checkIsSearchPage(from.path) && store.state.historySearch != "") {
                store.state.historySearch = ""
            }
        }
    }

    // Correct the pathMatch for url with parameters in order to activate the corresponding navigation item
    let params = {...to.params};
    params.pathMatch = to.params.pathMatch?.split('?')[0];

    // On paths with query parameters, replace the path only when directly navigating to the page or on back navigation
    let replace = from.fullPath === '/' || window.popStateDetected;

    if(to.params.pathMatch && to.params.pathMatch.includes('?')){
        next({path: to.path, params:params, replace: replace});
    }
    // Custom navigation for <page-name>/object/<object-slug> path type
    else if (to.fullPath.includes('object')) {
        next({ path: urlWithIdRedirect(to), params:params, replace: true});
    }
    else next();

    let app = getVueInstance('app');
    if(app){
        // Tell the app we're navigating to a different route (used in NavigationItem to programmatically hide sidebar)
        app.comingFromDifferentRoute = true;
        // Update router-view on browser navigation, if we're not navigating between simulated pages or while closing TermDetail or ObjectDetailLight overlays
        // Useful because there's no need to update the view in those cases and it preserves the right functionality on adminCentral
        if(window.popStateDetected && !to.fullPath.includes('?') && Vue.prototype.$lastOverlay.length === 0)
            updateViewKey();
    }

    window.popStateDetected = false; // Reset the popStateDetected flag
});

Vue.prototype.$screen = Vue.observable({
    width: window.innerWidth,
    height: window.innerHeight
});

window.addEventListener('resize', () => {
    Vue.prototype.$screen.width = window.innerWidth;
    Vue.prototype.$screen.height = window.innerHeight;
});

new Vue({
  router,
    store,
  render: (h) => h(App),
}).$mount('#app');
