<template>
  <span v-bind:class="{
        'block': is_block === 'true',
        'cta': is_cta === 'true',
        'paddingBottom': padding === 'true'}" class="link">
    <span class="icon icon-arrow-right"
          v-if="is_cta === 'true'"></span>
    <b-link
          :is_cta="is_cta"
          :is_block="is_block"
          :text="text"
          :to="toName"
          :href="externalLink"
          :target="target"
          v-on:click="handleClick">{{text}}</b-link>
  </span>

</template>

<script>
import { BLink } from 'bootstrap-vue';
import {updateViewKey} from "../helpers/AppHelpers";

export default {
  name: 'Link',
  props: ['is_cta', 'is_block', 'padding', 'text', 'destination', 'switchable'],
  components:{
    'b-link': BLink
  },
  data () {
    return{
      toName: '',
      externalLink: '',
      target: '_self'
    }
  },
  async created() {

    let destination = this.destination;

    if(this.switchable){
      destination = this.switchable.destination;
      // If it's an external link, get the url as href
      if(this.switchable.field === 'external'){
        this.externalLink = this.switchable.url;
        this.target = '_blank';
        return;
      }
      // If it's a telephone link, set the number as href=tel:
      else if(this.switchable.field === 'telephone'){
        this.externalLink = 'tel:' + this.switchable.number.replace(/\s/g, ''); // Remove the whitespaces
        return;
      }
    }

    // If it's an internal link
    // Get page name from destination id, and use it as :to value
    let params = `?@jcr:uuid=${destination}`;
    let query = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGENAV + params;

    const pagenavResponse = await fetch(query);

    const pageInfo = await pagenavResponse.json();

    if(pageInfo.results.length)
      this.toName = pageInfo.results[0]['@path'];
  },
  methods:{
    handleClick: function(){
      if(this.switchable && this.switchable.field === 'internal')
        updateViewKey();
    }
  }
}
</script>