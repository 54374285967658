<template>
  <div>
    <b-navbar type="light" variant="white">
        <b-navbar-nav>
          <b-navbar-toggle target="sidebar" class="hamburger hamburger--squeeze" id="sidebarToggleButton" @click="handleSidebar">
              <template v-slot:default="{ expanded }">
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
              </template>
          </b-navbar-toggle>
          <NavigationItem class="d-none d-lg-block"
              v-for="item in topnavigation"
              :key="item['@id']"
              :item="item"
              :isHomePage="isHomePage"/>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto" :class="{'detail-mode': detailMode}">
            <LanguageSelector />
            <b-nav-item class="no-background-image" v-if="searchPage" :href="searchPage">
                <span class="icon icon-search"></span>
            </b-nav-item>
        </b-navbar-nav>
    </b-navbar>
    <Sidebar
        :sidenavigation_1="sidenavigation_1"
        :sidenavigation_2="sidenavigation_2"
        :sidenavigation_3="sidenavigation_3"
        :additionalPadding="translateBannerVisible"
        :isHomePage="isHomePage"/>
  </div>
</template>

<script>
import Sidebar from './Sidebar';
import NavigationItem from './NavigationItem';
import LanguageSelector from './LanguageSelector';

export default {
  name: 'Navigation',
  props:['topnavigation', 'sidenavigation_1', 'sidenavigation_2', 'sidenavigation_3', 'detailMode', 'searchPage', 'isHomePage'],
  components: {
    Sidebar,
    NavigationItem,
    LanguageSelector
  },
  data(){
    return{
      translateBannerVisible: false
    }
  },
  methods:{
    handleSidebar: function(){
      let banner = document.querySelector('.goog-te-banner-frame');
      this.translateBannerVisible = banner && banner.style.visibility === 'visible' && banner.parentElement.style.display !== 'none';
    }
  }
};
</script>
<style lang="scss">
@import 'src/vars.scss';

@include media-breakpoint-down(md){
  .detail-mode{
    display:none !important;
  }
}
</style>
