import Root from './pages/Root';
import Child from './pages/Child';
import Image from './components/Image';
import TextComponent from './components/Text';
import Link from './components/Link';
import NavigationItem from './components/NavigationItem';
import FooterSection from './components/FooterSection';
import ObjectsList from "./components/ObjectsList";
import HTMLComponent from "./components/HTML";
import TwoCols from "./components/TwoCols";
import Container from "./components/Container";
import Search from "./components/Search";
import Carousel from "./components/Carousel";
import Slider from "./components/Slider";
import EventsList from "./components/EventsList";
import FlipbookComponent from "./components/Flipbook";

const config = {
  componentMappings: {
    // Pages
    'galerie-am-spalenberg-custom:pages/root': Root,
    'galerie-am-spalenberg-custom:pages/child': Child,

    // Components
    'galerie-am-spalenberg-custom:components/image': Image,
    'galerie-am-spalenberg-custom:components/text': TextComponent,
    'galerie-am-spalenberg-custom:components/link': Link,
    'galerie-am-spalenberg-custom:components/navigationItem': NavigationItem,
    'galerie-am-spalenberg-custom:components/footerSection': FooterSection,
    'galerie-am-spalenberg-custom:components/objectsList': ObjectsList,
    'galerie-am-spalenberg-custom:components/html': HTMLComponent,
    'galerie-am-spalenberg-custom:components/twocols': TwoCols,
    'galerie-am-spalenberg-custom:components/container': Container,
    'galerie-am-spalenberg-custom:components/search': Search,
    'galerie-am-spalenberg-custom:components/carousel': Carousel,
    'galerie-am-spalenberg-custom:components/slider': Slider,
    'galerie-am-spalenberg-custom:components/events': EventsList,
    'galerie-am-spalenberg-custom:components/flipbook': FlipbookComponent
  },
};

export default config;
