<template>

    <div class="terms-detail"
         :class="{open: isOpen}"
         v-if="isOpen">

      <Button
          :text="''"
          :variant="'transparent'"
          :onClick="goBack"
          class="close-button icon icon-close"/>

      <h1 class="headline"><strong>{{term.title}}</strong></h1>

      <div class="description"
        v-html="term.description">
      </div>
    </div>

</template>

<script>
  import Button from "./Button";
  import {activateObjectLinks, activateTermLinks, getVueInstance} from "../helpers/AppHelpers";
  import Vue from "vue";
  import { EventBus } from '../main';

  export default {
    name: 'TermDetail',
    props: ['term'],
    components:{
      Button
    },
    data(){
      return{
        isOpen: false,
        previousScroll: 0
      }
    },
    mounted(){
      window.addEventListener('popstate', this.popstate);
    },
    methods:{
      open(){
        // Store scroll position
        this.previousScroll = document.documentElement.scrollTop;

        // Scroll to top
        window.scrollTo(0, 0);

        // Open overlay
        this.isOpen = true;
        document.body.classList.add('noscroll');
        let app = getVueInstance('app');
        app.addLayer();

        // A term can contain links to other terms and objects as well!
        activateTermLinks();
        activateObjectLinks();
        // Enable closing the overlay on back navigation
        history.pushState(null, null, document.URL);
        // Keep track of the last opened overlay in order to only close that one on back navigation
        this.$nextTick(function(){
          Vue.prototype.$lastOverlay.push(this.term['@id']);
        });

        EventBus.$emit('simple-overlay-opened');
      },
      close(){
        // Restore scroll position
        window.scrollTo(0, this.previousScroll);

        // Close overlay
        this.isOpen = false;
        document.body.classList.remove('noscroll');
        let app = getVueInstance('app');
        app.removeLayer();
      },
      goBack(){
        history.back();
      },
      popstate(){
        // Close the overlay on back navigation if it was the last one opened
        if(this.isOpen && Vue.prototype.$lastOverlay[Vue.prototype.$lastOverlay.length-1] === this.term['@id']){
          Vue.prototype.$lastOverlay.pop();
          history.replaceState(null, null, document.URL);
          this.close();
          EventBus.$emit('simple-overlay-closed');

          if(Vue.prototype.$lastOverlay.length === 0){
            EventBus.$emit('all-simple-overlays-closed');
          }
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  @import 'src/vars.scss';
  .terms-detail {
    padding:$spacer;
    background-color: $custom-grey;
    margin-bottom:0;
    position:fixed;
    top:$navbar-height;
    left:0;
    bottom:0;
    right:0;
    height:calc(100% - 107px); // 100% - $navbar-height
    overflow-y: scroll;
    z-index:3;

    .headline{
      width:calc(100% - 2.5rem);
    }

    .close-button{
      height:1rem + rem(30px) !important;
      position:absolute;
      right:$spacer;
      top:$spacer - rem(10px);
      padding:0;
      margin:0 !important;
    }

    .description{
      max-width:67%;
      word-break: break-word;
    }
  }

  @include media-breakpoint-down(md){
    .terms-detail {
      padding:$spacer/2;
      top:$navbar-height-mobile;
      left:0;
      height:calc(100% - 67px); // 100% - $navbar-height-mobile

      overflow-y: scroll;
      z-index:3;

      .headline{
        width:calc(100% - 2.5rem);
      }

      .close-button{
        display:flex;
        position:fixed !important;
        padding:15px !important;
        right:rem(5px) !important;
        top:rem(11px) !important;
        color:black;
        z-index:3;
        background-color:white;
      }

      .description{
        max-width:100%;
      }
    }
  }
</style>

<style lang="scss">
  strong .term-link-wrapper .terms-detail{
    font-weight:initial;
  }

  em .term-link-wrapper .terms-detail{
    font-style: initial;
  }
</style>