<template>
  <div class="object-link-wrapper">
      <a @click.prevent="openDetail"
        class="no-background-image">
        <span class="inner-link" v-html="elementHTML"></span>
      </a>
    <ObjectDetailLight ref="detail" v-show="object" :object="object"/>
  </div>
</template>

<script>
import ObjectDetailLight from "./ObjectDetailLight";
export default {
  name: 'ObjectLink',
  props: ['domElement'],
  components:{
    ObjectDetailLight
  },
  data(){
    return {
      object: ''
    }
  },
  computed: {
    elementHTML(){
      return this.domElement.innerHTML;
    }
  },
  methods:{
    async openDetail(){
      // If the object is already in memory, don't call the endpoint
      if(this.object === ''){
        const objectResponse = await fetch(
          process.env.VUE_APP_HOST + '/.rest/gallery/v1/object/slug?slug=' + this.getObjectSlug()
        );

        const object = await objectResponse.json();

        this.object = object.object;
      }

      this.$refs.detail.open();
    },
    getObjectSlug(){
      // Get only the last slug from the path, so that even child objects can work correctly
      const splitPath = decodeURI(this.domElement.href).split('/');
      return splitPath[splitPath.length - 1].replaceAll('}', '');
    }
  }
};
</script>

<style scoped lang="scss">
  @import 'src/vars.scss';
  .object-link-wrapper{
    display:inline;
  }
</style>