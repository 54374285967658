import constants from "@/helpers/constants";
import Vue from "vue";
import TermLink from "../components/TermLink";
import ObjectLink from "../components/ObjectLink";

export const getVueInstance = function(elementId){
    return window.document.getElementById(elementId).__vue__;
}

export const getRootComponents = async function (){
    // Get the root components from the root page
    const root = await getRestResource('page_content', '');

    if(root){
        let rootcomponents = {};

        // Get the components listed in the ROOT_COMPONENTS constant array
        for (let component of constants.ROOT_COMPONENTS){
            if (root[component])
                rootcomponents[component] = root[component];
        }

        return rootcomponents;
    }
    else
        return;
}

export const urlWithIdRedirect = function (to) {
    let pathWithoutId;

    if(to.path.includes('/detail/')){
        const splitUrl = to.path.split('/detail/');
        pathWithoutId = splitUrl.join('?detail=');
    }
    else if(to.path.includes('/object/') && !to.path.includes('detail=')){
        const splitUrl = to.path.split('/object/');
        pathWithoutId = splitUrl.join('?object=');
    }

    return pathWithoutId;
}

export const getBasename = function(){
    return process.env.VUE_APP_MULTISITE ?
        '/' + location.pathname.split('/')[1].replace('.html', '')
        : process.env.VUE_APP_SITE_BASENAME;
}

//target = object - artist - default
export const hideContentForDetailMode = function(detailMode, target = 'default'){
    // Toggle visibility for all the elements on the page based on the detailMode flag and the show-on-detail-mode/show-on-artist-detail-mode classes
    // In order to hide all the other content on the page when an artist detail, object detail or flipbook modal is open

    // Get all the components on the page
    Vue.nextTick(function () {
        let componentsOnPage = document.querySelector('#mainArea').childNodes;

        // Hide or show each page component based on some evaluations made upon the target of the detail mode and the current state of the elements
        for(let component of componentsOnPage){

            // If the element requesting a detail mode change is an object
            // On detailMode === false, restore the visibility only of the components that are not currently hidden by an artist requested detail mode
            // On detailMode === true, hide any component without the "show-on-detail-mode" class
            if(target === 'object'){
                if(!detailMode && !component.classList?.contains('hide-on-artist-detail-mode'))
                    component.classList?.remove('hidden');
                else if(!component.classList?.contains('show-on-detail-mode'))
                    component.classList?.add('hidden');
            }

            // If the element requesting a detail mode change is an artist
            // On detailMode === false, restore the visibility of all the components
            // On detailMode === true, hide any component without the "show-on-detail-mode" or "show-on-artist-detail-mode" classes
            else if(target === 'artist'){
                if(!detailMode)
                    component.classList?.remove('hidden', 'hide-on-artist-detail-mode');
                else if(!component.classList?.contains('show-on-artist-detail-mode') && !component.classList?.contains('show-on-detail-mode'))
                    // Apply the "hide-on-artist-detail-mode" class to mark the elements hidden by the artist detail, so that they're not accidentally
                    // restored by an object requesting a detail mode change
                    component.classList?.add('hidden', 'hide-on-artist-detail-mode');
            }

            // On any other case, this is the default behavior
            // On detailMode === true, hide all the components without a "show-on-detail-mode" class
            // On detailMode === false, restore visibility
            else{
                if(!detailMode)
                    component.classList?.remove('hidden');
                else if(!component.classList?.contains('show-on-detail-mode'))
                    component.classList?.add('hidden');
            }
        }
    });
}

export const getLazyLoadImage = function(imageDamUrl){
    const baseUrl = `${process.env.VUE_APP_HOST}/.imaging/`;
    const sizes = ['(max-width: 600px) 480px',
                    '(min-width: 1087px) 967px'];

    // Default placeholder image used for missing assets
    let lazyImage = {
        src: `${process.env.VUE_APP_HOST}${process.env.VUE_APP_PLACEHOLDER_IMAGE}`,
        srcset: '',
        sizes: ''
    };

    // If we have an image, load it instead of the placeholder
    if(imageDamUrl){
        const fullName = imageDamUrl.replace('/dam/jcr:', '');

        lazyImage = {
            src: `${baseUrl}original/dam/${fullName}`,
            srcset: [`${baseUrl}967w/dam/${fullName} 967w`,
                `${baseUrl}480w/dam/${fullName} 480w`],
            sizes: sizes
        };
    }

    return lazyImage;
}

export const removeLazyLoadFromDuplicates = function(){
    let duplicateSlides = document.querySelectorAll('.swiper-slide-duplicate');

    for(let slide of duplicateSlides){
        slide.querySelector('.lazy-img-fadein')?.classList.remove('lazy-img-fadein');
    }
}

export const updateViewKey = function(){
    // Enable the app to remount a page even when navigating from the same route, contrary to default router behaviour
    let app = getVueInstance('app');
    app.viewKey += 1;
    app.isHomePage = checkIfHomePage();

    // Programmatically close the sidebar only if coming from a different route
    if (!app.comingFromDifferentRoute){
        let sidebar = document.querySelector('#sidebar');
        if(sidebar.style.display !== 'none'){
            let sidebarToggleButton = document.querySelector('#sidebarToggleButton');
            sidebarToggleButton.click();
        }
    }
    app.comingFromDifferentRoute = false;
}

export const scrollToTop = function(){
    setTimeout(() => {document.getElementById('app').scrollIntoView({behavior: "smooth"})}, 0);
}

// Get the basename based on the host. Used as a last resort when the application
// can't find a basename in any other more traditional way (e.g. on 404s where the basename itself contains mistakes).
export const getHostBasedBasename = function(){
    let basename = '/galerie';

    if(location.hostname.includes('pablo-picasso-seine-plakate'))
        basename = '/picasso-plakate';
    else if(location.hostname.includes('picasso'))
        basename = '/picasso';
    else if(location.hostname.includes('mourlot'))
        basename = '/mourlot';

    return basename;
}

export const checkIfHomePage = function(){
    let splitLocation = location.pathname.split('/');
    return location.pathname === '/' || splitLocation[splitLocation.length-1].toLowerCase() === 'home';
}

// Manually trigger a pageview tracking event with a custom template
export const trackPageView = function(self, title){
    // If the GA tracking is not active yet, create a backlog of trackings that
    // will be taken care of once the GA tracking is ready
    if(localStorage.isTrackingActive === 'false'){
        let missedTrackings = localStorage.missedTrackings ? JSON.parse(localStorage.missedTrackings) : [];
        missedTrackings.push(title);
        localStorage.missedTrackings = JSON.stringify(missedTrackings);
        return;
    }

    // If for some reason the self instance is undefined, just use the app one
    const app = getVueInstance('app');
    self = self ? self : app;

    // Trigger the pageview event
    self.$gtag.pageview({
        page_path: location.pathname,
        page_title: title,
        page_location: location.href
    });
}

export const activateTermLinks = function(){
    // Activate Term Link mechanism

    // Programmatically instantiate a Term Link component for each <a> term link to replace it

    // Pass the component object to Vue.extend to create a subclass of the Vue constructor.
    // Now we can create an instance out of it.
    let TermLinkConstructor = Vue.extend(TermLink);

    Vue.nextTick(function () {
        document.querySelectorAll('.term-link').forEach(link => {
            let instance = new TermLinkConstructor({
                propsData: { domElement: link} // Pass the props to our newly created component
            });
            instance.$mount(link); // Swap the <a> term link tag with the component
        });
    });
}

export const activateObjectLinks = function(){
    // Activate Object Link mechanism

    // Programmatically instantiate a Object Link component for each <a> object link to replace it

    // Pass the component object to Vue.extend to create a subclass of the Vue constructor.
    // Now we can create an instance out of it.
    let ObjectLinkConstructor = Vue.extend(ObjectLink);

    Vue.nextTick(function () {
        document.querySelectorAll('.object-link').forEach(link => {
            let instance = new ObjectLinkConstructor({
                propsData: { domElement: link} // Pass the props to our newly created component
            });
            instance.$mount(link); // Swap the <a> term link tag with the component
        });
    });
}

// Try getting a rest resource by trying with both a standard basename and a host based basename
export const getRestResource = async function(endpoint, pageUrl){
    let url = '';

    // If it's a root page, start directly with the host based basename
    let basename = localStorage.isRootPage === 'true' ? getHostBasedBasename() : getBasename();

    switch(endpoint){
        case 'page_content':
            url = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGES;
            break;
        case 'pagenav':
            url = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGENAV;
    }

    if(url){
        let restResponse = await fetch(url + basename + pageUrl);

        if(!restResponse.ok){
            basename = getHostBasedBasename();
            restResponse = await fetch(url + basename + pageUrl);

            if (!restResponse.ok)
                return;
        }

        return await restResponse.json();
    }
    else
        return;
}

// Formats a number as (e.g.) 1'000.00
export const formatNumber = function(number){

    if(number){
        const transformedNumber = number.replace("'", "");

        const formattedNumber = new Intl.NumberFormat('en-EN',{minimumFractionDigits: 2}).formatToParts(transformedNumber).map(({type, value}) => {
            switch (type) {
                case 'group': return `'`;
                default : return value;
            }
        }).reduce((string, part) => string + part);

        if(!isNaN(transformedNumber))
            return formattedNumber;
        else
            return number;
    }
}