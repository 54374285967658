<template>
  <div class="two-cols" :class="{paddingBottom: paddingBottom === 'true'}">
    <b-row>
      <b-col class="first-col" sm="12" md="6">
        <EditableArea v-if="column1" :content="column1"></EditableArea>
      </b-col>
      <b-col sm="12" md="6">
        <EditableArea v-if="column2" :content="column2"></EditableArea>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { EditableArea } from '../vue-editor';

export default {
  name: 'TwoCols',
  components: {
      EditableArea
  },
  props: ['column1', 'column2', 'paddingBottom']
};
</script>
<style scoped lang="scss">
  @import 'src/vars.scss';

  .paddingBottom{
    padding-bottom:$spacer;
  }

  @include media-breakpoint-down(md){
    .paddingBottom{
      padding-bottom:$spacer/2;
    }

    .first-col{
      padding-bottom:$spacer/2;
    }

  }
</style>