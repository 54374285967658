<template>
  <div id="app">
    <header>
      <Navigation
          :topnavigation="topnavigation"
          :sidenavigation_1="sidenavigation_1"
          :sidenavigation_2="sidenavigation_2"
          :sidenavigation_3="sidenavigation_3"
          :detailMode="detailMode"
          :searchPage="searchPage"
          :isHomePage="isHomePage"
      />
    </header>

    <router-view :key="viewKey"></router-view>

  </div>
</template>

<script>
import {checkIfHomePage, getRestResource} from "./helpers/AppHelpers";
import Navigation from './components/Navigation';
import {setOptions, bootstrap }from "vue-gtag";

export default {
  name: 'app',
  components: {
    Navigation
  },
  data() {
    return {
      topnavigation: [],
      sidenavigation_1: [],
      sidenavigation_2: [],
      sidenavigation_3: [],
      rootcomponents: {},
      searchPage: '',
      openLayers: 0,
      detailMode: false,
      viewKey: 1, // Used to force a page to remount even if navigating to the same route
      comingFromDifferentRoute: false, // Used to be able to programmatically close the sidebar only if coming from the same route
      measurementId: '',
      stagingMeasurementId: '',
      isHomePage: checkIfHomePage()
    };
  },
  watch:{
    detailMode: function(val){
      if(val){
        // Hide Google Translate elements on detail mode
        document.querySelector('#google_translate_element')?.classList.add('detail-mode');
        document.querySelector('.skiptranslate')?.classList.add('hidden');
        document.querySelector('body').style.top = '0';

      }
      else{
        // Restore Google Translate elements off detail mode
        document.querySelector('#google_translate_element')?.classList.remove('detail-mode');
        document.querySelector('.skiptranslate')?.classList.remove('hidden');

        if(document.querySelector('.goog-te-banner-frame') && document.querySelector('.goog-te-banner-frame').style.visibility === 'visible')
          document.querySelector('body').style.top = '40px';
      }
    },
    openLayers(val){
      if(val)
        this.detailMode = true;
      else
        this.detailMode = false;
    }
  },
  async mounted() {
    localStorage.isTrackingActive = false;

    const pagenav = await getRestResource('pagenav', '');

    if (pagenav === undefined)
      return;

    // Assign each page to the correct navigation menu based on the values specified
    // by the user on the page dialog
    let topnavigation = [];
    let sidenavigation_1 = [];
    let sidenavigation_2 = [];
    let sidenavigation_3 = [];
    let searchPage = '';
    let measurementId = '';
    let stagingMeasurementId = '';

    if (pagenav['searchPage'])
      searchPage = pagenav['searchPage']['@path']

    if (pagenav['measurementId'])
      measurementId = pagenav['measurementId']

    if (pagenav['stagingMeasurementId'])
      stagingMeasurementId = pagenav['stagingMeasurementId']

    pagenav['@nodes'].forEach(function (nodeName) {
      let node = pagenav[nodeName];

      if (node.showOnTopNav && JSON.parse(node.showOnTopNav))
        topnavigation.push(node);

      if (node.showOnSideNav1 && JSON.parse(node.showOnSideNav1))
        sidenavigation_1.push(node);

      if (node.showOnSideNav2 && JSON.parse(node.showOnSideNav2))
        sidenavigation_2.push(node);

      if (node.showOnSideNav3 && JSON.parse(node.showOnSideNav3))
        sidenavigation_3.push(node);
    });

    this.topnavigation = topnavigation;
    this.sidenavigation_1 = sidenavigation_1;
    this.sidenavigation_2 = sidenavigation_2;
    this.sidenavigation_3 = sidenavigation_3;
    this.searchPage = searchPage;
    this.measurementId = measurementId;
    this.stagingMeasurementId = stagingMeasurementId;

    // Enable analytics with the measurement ID specified oon the root page's properties
    // Staging test only analytics
    if ((location.hostname.includes('staging') || location.hostname.includes('localhost')) && !location.href.includes('/.magnolia/') && this.stagingMeasurementId) {
      setOptions({
        config: {id: this.stagingMeasurementId}
      })

      await bootstrap().then(() => localStorage.isTrackingActive = true);
    }
    // Production public instances only analytics
    else if (this.measurementId && !location.hostname.includes('staging') && !location.hostname.includes('author') && !location.hostname.includes('localhost') && !location.href.includes('/.magnolia/')) {
      setOptions({
        config: {id: this.measurementId}
      })

      await bootstrap().then(() => localStorage.isTrackingActive = true);
    }
  },
  methods:{
    addLayer(){
      this.openLayers += 1;
    },
    removeLayer(){
      this.openLayers -= 1;
    }
  }
};
</script>
