<template>
  <div id="event-detail">
    <transition name="fade">
      <div v-if="loading" class="lazy-img-spinner w-100 text-center paddingY">
        <b-spinner type="grow" label="Loading..."></b-spinner>
      </div>
      <section v-else v-show="!detailMode && name">

        <h1><strong>{{name}}</strong></h1>
        <h2>{{fromDate}}<span v-if="toDate"> bis {{toDate}}</span></h2>

        <div
            v-html="description"
            class="description"></div>

        <Category
            v-for="(category, index) in categories"
            :key="category.id"
            :category="category"
            :categoryIndex="index"
            :parentId="'event-detail'"/>

      </section>
    </transition>

    <transition name="fade">
      <ObjectDetail
          v-show="detailMode"
          :object="currentObject"
          :parentId="'event-detail'"
          :eventShowPrice="showPrice"/>
    </transition>

  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';
import Category from './Category';
import ObjectDetail from './ObjectDetail'
import {
  getVueInstance,
  hideContentForDetailMode,
  trackPageView,
  activateTermLinks,
  activateObjectLinks
} from "../helpers/AppHelpers";
import {EventBus} from "../main";

export default {
  name:'EventDetail',
  props: ['mode', 'eventBasicInfo'],
  components: {
    Category,
    ObjectDetail,
    'b-spinner': BSpinner
  },
  data () {
    return {
      event: {},
      detailMode: false,
      currentObject: {},
      loading: true
    }
  },
  computed: {
    groupByCategory (){
      return this.event.groupByCategory;
    },
    categories (){
      if(this.groupByCategory === 'true')
        return this.event.categories.filter(category => category.categoryName !== '')
      else
        return [{
          categoryName: '',
          objects: this.event.objects ? this.event.objects.flat() : []
        }]
    },
    showPrice (){
      return this.event.showPrice;
    },
    name (){
     return this.event.jcrName;
    },
    fromDate(){
      return this.event.fromDate ? this.getDateString(this.event.fromDate) : '';
    },
    toDate(){
      return this.event.toDate ? this.getDateString(this.event.toDate) : '';
    },
    description (){
      return this.event.description;
    }
  },
  watch:{
    detailMode: function(val){
      if(!val){
        history.pushState(null, null, location.href.split('/object')[0]);
        document.title = this.name;
        trackPageView(this, `${this.name}`); // Custom pageview event because Google Analytics doesn't detect this as a new page
      }

      hideContentForDetailMode(val);
    },
    currentObject: function(object){
      if(object.updateHistory){
        history.pushState(null, null, `${location.href.split('/object/')[0]}/object/${object.slug}`);
        document.title = `${object.artist.name}, ${object.title}`;
        trackPageView(this, `${object.artist.name}, ${object.title}`); // Custom pageview event because Google Analytics doesn't detect this as a new page
      }
    }
  },
  async created(){
    await this.getPageContentFromParameters();

    // Necessary manipulation for vanity URLs when a TermDetail or an ObjectDetailLight overlay closes
    EventBus.$on('simple-overlay-closed', this.simpleOverlayClosed);
  },
  beforeDestroy() {
    EventBus.$off('simple-overlay-closed', this.simpleOverlayClosed);
  },
  methods: {
    getPageContentFromParameters: async function(){

      if(this.mode !== 'elementDetail' &&  this.mode !== 'objectDetail' && !location.href.includes('detail'))
        return;

      this.loading = true;
      this.eventInfo = {};
      const url = new URL(location.href);

      let slug = url.searchParams.get("detail") ?
          url.searchParams.get("detail") :
          this.eventBasicInfo.slug ?
          this.eventBasicInfo.slug :
          null;

      let objectSlug = '';

      if(slug === null || slug === undefined)
        slug = location.href.split('detail/')[1];

      if(slug === null || slug === undefined)
        return;

      history.replaceState(null, null, decodeURIComponent(location.href.replace('?detail=', '/detail/')));

      if(slug.includes('object')){
        let splitSlug = slug.split('/object/')
        slug = splitSlug[0];
        objectSlug = splitSlug[1];
      }
      else {
        objectSlug = '';
        this.detailMode = false;
      }

      // Get the event from the endpoint
      let api = `${process.env.VUE_APP_HOST}/.rest/events/v1/event/slug?slug=${slug}`;

      // Run the query
      let eventResponse = await fetch(api);

      this.event = await eventResponse.json();

      activateTermLinks();
      activateObjectLinks();

      this.loading = false;

      if(objectSlug)
        this.selectObject(objectSlug);
      else{
        document.title = this.name;
        trackPageView(this, `${this.name}`); // Custom pageview event because Google Analytics doesn't detect this as a new page
      }
    },
    // Opens the Object Detail modal
    openObjectDetailComponent: function(){
      setTimeout(() => {document.getElementById('app').scrollIntoView({behavior: "smooth"})}, 0);
      this.detailMode = true;
      // Set app detail mode to toggle visibility of certain elements
      let app = getVueInstance('app');
      app.addLayer();

      activateTermLinks();
      activateObjectLinks();
    },
    // Closes the Object Detail modal
    closeObjectDetailComponent: function(clickedId){
      this.detailMode = false;
      // Set app detail mode to toggle visibility of certain elements
      let app = getVueInstance('app');
      app.removeLayer();

      // Restore scroll position on objects list
      this.$nextTick(function () {
        let elem = document.querySelector('#' + clickedId);
        if(elem){
          let box = elem.getBoundingClientRect();
          window.scrollTo(0, box.top + window.pageYOffset);
        }
      });
    },
    selectObject: function(objectSlug){
      this.$nextTick(function () {
        for(let categoryIndex in this.categories){
          let category = this.categories[categoryIndex];
          for(let objectIndex in category.objects){
            let object = category.objects[objectIndex];
            if(object.slug === objectSlug){
              let domId = `object${categoryIndex}-${objectIndex}`;

              this.currentObject = {
                updateHistory: false,
                category: category,
                index: parseInt(objectIndex),
                clickedId: domId, // Used to restore scroll position on objects list
                ...object
              };

              document.title = `${object.artist.name}, ${object.title}`;
              trackPageView(this, `${object.artist.name}, ${object.title}`); // Custom pageview event because Google Analytics doesn't detect this as a new page

              this.openObjectDetailComponent();

            }
          }
        }
      });
    },
    onPopState: function(){
      this.getPageContentFromParameters();
    },
    getDateString: function(date){
      const newDate = new Date(date);
      return newDate.toLocaleDateString('DE',{month: 'long', // "June"
        day: '2-digit', year: 'numeric'});
    },
    simpleOverlayClosed: function(){
      // Necessary manipulation for vanity URLs when a TermDetail or an ObjectDetailLight overlay closes
      history.replaceState(null, null, decodeURIComponent(location.href.replace('?detail=', '/detail/').replace('?object=', '/object/')));
    }
  }
};
</script>
<style scoped lang="scss">
@import 'src/vars.scss';

h1{
  margin-bottom:0;
}

h2{
  margin-bottom: $spacer;
}

.description{
  padding-bottom: $spacer;
  max-width: 67%;
}

@include media-breakpoint-down(md){
  h2{
    margin-bottom: $spacer/2;
  }

  .description{
    max-width: 100%;
  }
}
</style>