<template>
  <b-sidebar :class="{'additional-padding': additionalPadding}" id="sidebar" title="Sidebar" bg-variant="white" shadow no-header backdrop>
    <b-nav vertical class="w-100">
      <SidebarSection
          :sidenavigation="sidenavigation_1"
          :isHomePage="isHomePage"/>
      <SidebarSection
          :sidenavigation="sidenavigation_2"
          :isHomePage="isHomePage"/>
      <SidebarSection
          :sidenavigation="sidenavigation_3"
          :isHomePage="isHomePage"/>
    </b-nav>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue';
import SidebarSection from './SidebarSection';

export default {
  name: 'Sidebar',
  props: ['sidenavigation_1', 'sidenavigation_2', 'sidenavigation_3', 'additionalPadding', 'isHomePage'],
  components:{
    'b-sidebar': BSidebar,
    SidebarSection
  }
}
</script>