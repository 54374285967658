<template>
    <footer>
        <b-row no-gutters>
            <b-col sm="12" md="6">

                <EditableArea class="d-flex flex-column justify-content-between"
                      v-if="footerContentLeft"
                      v-bind:content="footerContentLeft" />

            </b-col>
            <b-col sm="12" md="6"
                   class="d-flex flex-column justify-content-between">

                <EditableArea class="d-flex flex-column justify-content-between"
                      v-if="footerContentRight"
                      v-bind:content="footerContentRight" />

                <p class="copyright">
                    &copy; 1997-{{ new Date().getFullYear() }}
                    <span v-if="legalPageTo">- </span>
                    <b-link v-if="legalPageTo" :to="legalPageTo" @click="updateViewKey">{{legalPageLabelFromRoot}}</b-link>
                </p>
            </b-col>
        </b-row>
    </footer>
</template>

<script>
import { getVueInstance, getRootComponents, updateViewKey } from '../helpers/AppHelpers'
import { EditableArea } from '../vue-editor';
import { BLink } from 'bootstrap-vue';

export default {
    name: 'Footer',
    components: {
        EditableArea,
        'b-link': BLink
    },
    props: ['footerLeft', 'footerRight', 'legalPage', 'legalPageLabel'],
    data (){
      return {
          footerContentLeft: '',
          footerContentRight: '',
          legalPageFromRoot: '',
          legalPageTo: '',
          legalPageLabelFromRoot: ''
      }
    },
    async created(){
        // If the footer has an editable area, meaning that it's a root page
        if (this.footerLeft && this.footerRight && this.legalPage && this.legalPageLabel){
            this.footerContentLeft = this.footerLeft; // Get the content from the left area
            this.footerContentRight = this.footerRight; // Get the content from the right area
            this.legalPageFromRoot = this.legalPage; // Get the legal page destination
            this.legalPageLabelFromRoot = this.legalPageLabel; // Get the legal page label
        } else { // If it doesn't, meaning that it's a child page

          // Get the footer content from the root page
          let app = getVueInstance('app');

          // If we already have it, just apply the content
          if(app.rootcomponents && app.rootcomponents.footerLeft && app.rootcomponents.footerRight && app.rootcomponents.legalPage && app.rootcomponents.legalPageLabel){
            this.footerContentLeft = app.rootcomponents.footerLeft;
            this.footerContentRight = app.rootcomponents.footerRight;
            this.legalPageFromRoot = app.rootcomponents.legalPage;
            this.legalPageLabelFromRoot = app.rootcomponents.legalPageLabel;
          }
          else{
            // If we don't have it, call the function to retrieve it and save it to the app instance
            app.rootcomponents = await getRootComponents();

            if(app.rootcomponents){
                this.footerContentLeft = app.rootcomponents.footerLeft;
                this.footerContentRight = app.rootcomponents.footerRight;
                this.legalPageFromRoot = app.rootcomponents.legalPage;
                this.legalPageLabelFromRoot = app.rootcomponents.legalPageLabel;
            }
          }
        }

        // Get legal page name from destination id, and use it as :to value
        let params = `?@jcr:uuid=${this.legalPageFromRoot}`;
        let query = process.env.VUE_APP_HOST + process.env.VUE_APP_REST_PAGENAV + params;

        const pagenavResponse = await fetch(query);

        const pageInfo = await pagenavResponse.json();

        if(pageInfo.results.length)
            this.legalPageTo = pageInfo.results[0]['@path'];
    },
    methods:{
        updateViewKey: function(){
            updateViewKey();
        }
    }
};
</script>