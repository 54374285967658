<template>

  <b-col xs="12" sm="6" md="4" lg="3">
    <ObjectsListItem
        v-for="element in elements"
        :key="element.id"
        :element="element"
        :parentId="parentId"/>
  </b-col>

</template>

<script>
import ObjectsListItem from "@/components/ObjectsListItem";

export default {
  name: "ObjectsListColumn",
  props: ["elements", "parentId"],
  components: {
    ObjectsListItem
  }
};
</script>
<style lang="scss" scoped>
.list{
  padding:10px 0;
  max-width:75%;
}
</style>