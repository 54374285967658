<template>
  <div v-show="isOpen" class="image-detail">

    <Button
        :text="''"
        :variant="'transparent'"
        :onClick="closeObjectDetailImage"
        class="close-button icon icon-close"/>

    <div v-lazyload>
      <b-img
        class="lazy-img-fadein"
        :data-url="lazyImage.src"
        :src="lazyImage.src"
        :srcset="lazyImage.srcset"
        :sizes="lazyImage.sizes"
        fluid-grow />
    </div>

    <div class="text-center">
      <span><strong v-if="artist">{{artist.name}}</strong>, {{title}}</span>
    </div>

  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import Button from "./Button";
import {getVueInstance, getLazyLoadImage} from "../helpers/AppHelpers";

export default {
  name:'ObjectDetailImage',
  props: ['url', 'artist', 'title', 'isOpen', 'objectId'],
  components: {
    'b-img': BImg,
    Button
  },
  computed:{
    // Use "imageUrl" instead of "url" for testing with npm run serve
    imageUrl(){
      if(location.href.includes('localhost:8081'))
        return 'http://localhost:8081/images/image2.png';
      else
        return this.url;
    },
    lazyImage(){
      return getLazyLoadImage(this.url);
    }
  },
  methods:{
    // Closes this modal notifying the parent Object Detail component
    closeObjectDetailImage: function() {
      let objectDetailComponent = getVueInstance('object-detail' + this.objectId);
      objectDetailComponent.closeObjectDetailImage();
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/vars.scss';

.image-detail{
  img{
    padding: $spacer*3 $spacer*5 $spacer*2 $spacer*5;
  }
}

@include media-breakpoint-down(sm){
  .image-detail{
    img{
      padding: $spacer*3 0 $spacer*2 0;
    }
  }
}
</style>