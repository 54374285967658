<template>
    <section class="footer-section">
        <div><strong>{{ title }}</strong></div>
        <EditableArea v-if="footerSection" v-bind:content="footerSection" />
    </section>
</template>

<script>
import { EditableArea } from '../vue-editor';

export default {
    name: 'FooterSection',
    props: ['title', 'footerSection'],
    components: {
        EditableArea
    }
};
</script>