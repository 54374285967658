<style scoped>
.wrapper-field-search {
  position: relative;
}
.wrapper-field-search input {
  width: 100%;
  padding: 5px 10px;
  border-color: transparent;
}

.wrapper-field-search input:focus {
  border-color: transparent;
}

.message-results, .no-results{
  text-align: left;
  padding-top: 1.5rem;
}

.row-results {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.row-results h2, .row-results .url {
  margin-bottom: 10px;
}

.wrapper-results a.router-link-active {
  font-weight: initial;
}

.wrapper-field-search .sc-icon {
  position: absolute;
  right: 10px;
  top: 0px;
}

@media(min-width: 768px) {
  .wrapper-field-search input {
    width: 65%;
  }

  .wrapper-field-search .sc-icon {
    position: relative;
    left: -40px;
    top: 5px;
  }

}

</style>

<template>
  <div>
    <div class="wrapper-search">
      <div class="wrapper-field-search">
        <input type="text" ref="searchInput" v-if="inputIsVisible" v-model="searchInput" @keyup="onChangeInput()" @keyup.enter="onEnter" :placeholder="searchPlaceholder">
        <span class="icon sc-icon"
              ref="searchicon"
              v-bind:class="iconSearchClass"
              @click="showResults()"
              ></span>
      </div>

    </div>
    <div class="wrapper-results">
      <div class="message-results" v-if="iconSearchClass['icon-close'] && searchResults.length > 0">Suchresultat für: <b>{{searchInput}}</b></div>
      <div class="message-results" v-if="iconSearchClass['icon-search']">{{searchText}}</div>
      <div class="row-results" :class="{'last-child': $index == searchResults.length - 1}" v-for="(item, $index) in searchResults" :key="$index">
        <h2><b>{{item.title}}</b></h2>
        <div class="url"><b-link :to="item.url" v-on:click="updateViewKey">{{item.url_show}}</b-link></div>

        <div v-html="item.excerpt"></div>

      </div>
      <infinite-loading v-if="showResultsBool" @infinite="infiniteHandler">
        <div class="no-results" slot="no-results"> <span class="text-danger">Leider keine Resultate gefunden.</span></div>
        <div slot="no-more"></div>
      </infinite-loading>
    </div>
  </div>

</template>

<script>
import { BLink } from 'bootstrap-vue';
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import {updateViewKey} from "@/helpers/AppHelpers";

const api = process.env.VUE_APP_HOST + '/.rest/gallery/v1/search';

function getMaxExcerptLength(item) {
  let max_excerpt_length = item.excerpt.length
  if(item.excerpt.length >= 202) {
    max_excerpt_length = 202
  }
  return max_excerpt_length
}

function getIndicesOf(searchStr, str, caseSensitive) {
  let searchStrLen = searchStr.length;
  if (searchStrLen == 0) {
    return [];
  }
  let startIndex = 0, index, indices = [];
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }
  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
}


function replaceAllOccurences(excerpt, searchInput, indices) {

  let substr_first = "";
  let substr_replace = "";
  let substr_second = "";
  let lastIndex = -1
  let indexDiff = 0
  let stringRemained = excerpt
  let finalString = ""
  for(let index of indices) {
    if(lastIndex == -1) {
      indexDiff = index
      substr_first = stringRemained.substring(0, indexDiff);

    } else {
      indexDiff = index - lastIndex
      substr_first = stringRemained.substring(0, indexDiff);
    }

    substr_replace = "<b>" + stringRemained.substring(indexDiff, (indexDiff + searchInput.length)) + "</b>";
    substr_second = stringRemained.substring(indexDiff + (searchInput.length));

    finalString += substr_first + substr_replace + substr_second
    stringRemained = substr_second
    lastIndex = index;
  }

  return finalString

}


export default {
  name: "Search",
  props: ["searchPlaceholder", "searchText"],
  data: function () {
    return {
      inputIsVisible: false,
      searchInput: "",
      iconSearchClass: {
        "icon-search": true,
        "icon-close": false
      },
      searchResults: [],
      page: 0,
      showResultsBool: false
    }
  },
  components: {
    InfiniteLoading,
    'b-link': BLink
  },
  mounted() {

    let inputSearch = this.$store.getters.getSearchHistory()
    if(inputSearch) {
        this.searchInput = inputSearch
        setTimeout(() => {
          this.$refs.searchicon.click()
        }, 0)
    }

    this.showInput();


  },
  methods: {
    showInput() {
      // Show the input component
      this.inputIsVisible = true;

      // Focus the component, but we have to wait
      // so that it will be showing first.
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    onEnter() {
      this.showResults()
    },
    showResults() {
      this.searchResults = []
      if(this.searchInput !== "" && !this.iconSearchClass["icon-close"]) {
        //get data
        this.iconSearchClass["icon-search"] = false;
        this.iconSearchClass["icon-close"] = true;
        this.showResultsBool = true;
        this.page = 0;

      } else if(this.searchInput !== "" && this.iconSearchClass["icon-close"]) {
        //empty results array

        this.iconSearchClass["icon-search"] = true;
        this.iconSearchClass["icon-close"] = false;
        this.searchInput = "";
        this.showResultsBool = false;
      }
    },
    infiniteHandler($state) {
      if(!this.showResultsBool) {
        return
      }
      let length = 5;
      let start = this.page * length;
      axios.get(api, {
        params: {
          q: this.searchInput,
          start,
          length
        },
      }).then((response) => {
        let data =response.data.data
        if (data.length) {
          this.$store.commit("addSearchHistory", this.searchInput)
          this.page += 1;
          for(let i=0; i < data.length; i++) {
            let item = data[i]
            //remove html
            item.excerpt = item.excerpt.replace(/(<([^>]+)>)/gi, "");
            let lowercase_excerpt = item.excerpt.toLowerCase()
            let lowercase_searchInput = this.searchInput.toLowerCase()
            let index = lowercase_excerpt.indexOf(lowercase_searchInput)
            let indices = getIndicesOf(this.searchInput, item.excerpt)
            if (index != -1 && index <= (202 - this.searchInput.length)) {

              item.excerpt = replaceAllOccurences(item.excerpt, this.searchInput, indices)
              let max_excerpt_length = getMaxExcerptLength(item)
              let excerpt = item.excerpt.substring(0, max_excerpt_length)
              excerpt = excerpt + "..."
              item.excerpt = excerpt
            } else if(index != -1 && index >= (202 - this.searchInput.length)) {
              item.excerpt = replaceAllOccurences(item.excerpt, this.searchInput, indices)
              let start_empty_space = item.excerpt.indexOf(" ", index - (202 - this.searchInput.length)/2)
              let max_excerpt_length = getMaxExcerptLength(item)
              let excerpt = item.excerpt.substring(start_empty_space + 1, start_empty_space + max_excerpt_length)
              excerpt = excerpt + "..."
              item.excerpt = excerpt

            } else {
              let max_excerpt_length = getMaxExcerptLength(item)
              let excerpt = item.excerpt.substring(0, max_excerpt_length)
              excerpt = excerpt + "..."
              item.excerpt = excerpt

            }

            if(item.url.endsWith(".html")) {
              item.url = item.url.replace(".html", "")

            }

            item.url = `/${location.pathname.split('/')[1]}${item.url}`;
            item.url_show = `${location.origin}${item.url}`;

            data[i] = item
          }
          this.searchResults.push(...data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    onChangeInput() {
        this.iconSearchClass["icon-search"] = true;
        this.iconSearchClass["icon-close"] = false;
        this.showResultsBool = false;
        this.page = 0;
    },
    updateViewKey(){
      updateViewKey();
    }

  }
}
</script>

<style scoped>
.wrapper-results div.row-results.last-child {
  border-bottom: none!important;
}
</style>