<template>
  <b-nav-item-dropdown :lazy="true" :text="textSelect" right class="no-background-image language-selector">
    <b-dropdown-item v-for="(item, index) in optionList" :key="index" :active="activeTab == item.value"  @click="selectItem(item, index)" href="#">{{item.text}}</b-dropdown-item>
  </b-nav-item-dropdown>
</template>


<script>
export default {
  name: 'LanguageSelector',
  filters: {
    uppercase: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.toUpperCase()
    }
  },
  data () {
    return{
      activeTab: null,
      optionList: [],
      textSelect: "Sprache wählen",
      bannerPresent: false
    }
  },
  async mounted() {
    window.addEventListener('load', function onLoad() {
      let selectField = document.querySelector("#google_translate_element select");

      // Populate the dropdown with the elements of the hidden google translate dropdown for the first time
      this.populateDropdown(selectField);

      // Update the dropdown content when the hidden google translate dropdown changes
      let observer = new MutationObserver(function() {
        this.optionList = [];
        this.populateDropdown(selectField);
        let children = document.querySelector("#google_translate_element select").children;

        // If the option with an empty value is back, meaning that we're displaying the original language
        // Set the label back to the original one
        children.forEach(child => {
          if (child.value === ""){
            this.activeTab = "";
            this.textSelect = "Sprache wählen";
          }
        })
      }.bind(this));

      observer.observe(selectField, {
        childList: true
        , subtree: true
        , attributes: true
        , characterData: false
      })
    }.bind(this));
  },
  methods: {
    selectItem: function(language, index) {
        let selectField = document.querySelector("#google_translate_element select");
        // find desired language and change the former language of the hidden selection-field
        selectField.selectedIndex = index;
        // trigger change event afterwards to make google-lib translate this side
        selectField.dispatchEvent(new Event('change'));
        this.activeTab = language.value;
        this.textSelect = language.text;
    },
    populateDropdown: function(sourceSelect){
      // Populate the language options dropdown based on another select of choice
      sourceSelect.forEach(option => {
        let item = {
          text: option.text,
          value: option.value
        };

        this.optionList.push(item);
      });
    }
  }
};
</script>
