<template>
  <li class="nav-item">
    <router-link
        class="nav-link"
        :class="{'router-link-active':isHomePage && isHomeLink}"
        :to="item['@path']"
        @click.native="updateViewKey">
      <span>{{ item.title }}</span>
    </router-link>
  </li>

</template>

<script>
import {updateViewKey} from "../helpers/AppHelpers";

export default {
  name: 'NavigationItem',
  props:['item', 'isHomePage'],
  data(){
    return{
      isHomeLink: this.item['@path'].split('/')[2] === 'home'
    }
  },
  methods:{
    updateViewKey: function(){
      updateViewKey();
    }
  }
};
</script>