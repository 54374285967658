<template>
  <transition name="fade">
    <div class="category" v-show="objects && objects.length > 0">
      <h2 v-if="name"><strong>{{name}}</strong></h2>

      <div class="objects-container">
        <Object
            v-for="(object, index) in objects"
            :key="object.id"
            :object="object"
            :index="index"
            :category="category"
            :categoryIndex="categoryIndex"
            :parentId="parentId"/>
      </div>

    </div>
  </transition>
</template>

<script>
import Object from "./Object";

export default {
  name:'Category',
  props: ['category', 'categoryIndex', 'parentId'],
  components: {
    Object
  },
  computed:{
    objects: function(){
      return this.category.objects;
    },
    name: function(){
      return this.category.categoryName !== 'uncategorized' ? this.category.categoryName : '';
    }
  }
};
</script>
<style lang="scss">
@import 'src/vars.scss';
.category{
  padding-bottom: $spacer/2;

  h2{
    margin-bottom:0;
  }

  .objects-container{
    margin:0;
    margin-left:-$spacer/2;
    margin-right:-$spacer/2;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
  }
}

@include media-breakpoint-down(md){
  .category{
    padding-bottom: $spacer/4;

    .objects-container{
      margin-left:-$spacer/4;
      margin-right:-$spacer/4;
    }
  }
}
</style>