<template>
    <span class="link block list">

      <b-link
          v-if="!inPreviewMode"
          v-on:click.ctrl="openInNewTab"
          v-on:click.meta="openInNewTab"
          v-on:click.exact="openDetailComponent"
          @click.prevent
          :href="href"
          v-html="element.name">
      </b-link>

      <div
          v-if="inPreviewMode"
          v-on:click.ctrl="openInNewTab"
          v-on:click.stop.meta="openInNewTab"
          v-on:click.exact="openDetailComponent"
          v-html="element.name"
          class="inner-link">
      </div>

    </span>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import {getVueInstance} from "@/helpers/AppHelpers";
import { inMgnlIframe, inEditorEdit } from "../vue-editor";

export default {
  name: 'ObjectsListItem',
  props: ['element', 'parentId'],
  components: {
    'b-link': BLink
  },
  computed:{
    href (){
      return `${location.origin}${location.pathname.replace('.html', '')}/detail/${this.element.slug}`
    },
    inPreviewMode(){
      return inMgnlIframe && !inEditorEdit;
    }
  },
  methods:{
    openDetailComponent: function (){
      let objectsListComponent = getVueInstance(this.parentId);
      let elementDetails = {
        updateHistory: true,
        ...this.element
      }

      objectsListComponent.currentElement = elementDetails;
      objectsListComponent.openElementDetailComponent();
    },
    openInNewTab: function(){
      window.open(this.href, '_blank');
    }
  }
};
</script>
<style lang="scss" scoped>
  .list{
    padding:10px 0;
    max-width:75%;
  }

  div{
    display:inline;
  }
</style>