<template>
  <div class="obj-image-container">
    <div v-lazyload :style="{'border-color':borderColor}">
      <b-img
        class="lazy-img-fadein"
        :data-url="lazyImage.src"
        :src="lazyImage.src"
        :srcset="lazyImage.srcset"
        :sizes="lazyImage.sizes"
        fluid />
      <div class="lazy-img-spinner">
        <b-spinner type="grow" label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg, BSpinner } from 'bootstrap-vue'
import {getLazyLoadImage} from "../helpers/AppHelpers";

export default {
  name:'ObjectImage',
  props: ['url', 'activationStatus'],
  components: {
    'b-img': BImg,
    'b-spinner': BSpinner
  },
  computed:{
    // Use "imageUrl" instead of "url" for testing with npm run serve
    imageUrl(){
      if(location.href.includes('localhost:8081'))
        return 'http://localhost:8081/images/image2.png';
      else
        return this.url;
    },
    lazyImage(){
      return getLazyLoadImage(this.url);
    },
    borderColor(){
      const activationColors = ['#F54200','#FFB300', '#599900', '#F2F2F2'];

      if(this.activationStatus !== undefined)
        return activationColors[this.activationStatus];
      else
        return activationColors[3];
    }
  }
};
</script>

<style lang="scss">
@import 'src/vars.scss';

.obj-image-container{
  div{
    width:100%;
    height:383px;
    border:2px solid $custom-grey;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
  }
}

.img-fluid{
  max-height:100%;
}

img.lazy-img-fadein {
  width:0;
}

@include media-breakpoint-down(sm){
  .obj-image-container{
    div{
      width:100%;
      height:auto;
      border:0;
    }
  }

  .img-fluid{
    width:100%;
  }
}
</style>