<template>
  <div class="Basic">
    <main></main>

    <Footer :footer-left="footerLeft" :footer-right="footerRight" :legal-page="legalPage" :legal-page-label="legalPageLabel"/>

  </div>
</template>

<script>
import Footer from '../components/Footer';

export default {
  name: 'Root',
  components: {
    Footer
  },
  props: ['title', 'metadata', 'footerLeft', 'footerRight', 'legalPage', 'legalPageLabel']
};
</script>