<template>
  <div>

    <b-link
        v-if="!inPreviewMode"
        v-on:click.ctrl="openInNewTab"
        v-on:click.meta="openInNewTab"
        v-on:click.exact="openObjectDetailComponent"
        @click.prevent
        :href="href"
        :id="domId"
        class="object">

      <ObjectImage
          :url="imageUrl"
          :activationStatus="activationStatus"/>

      <div class="object-info">
        <span class="link block">
          <b-link
              v-on:click.ctrl="openInNewTab"
              v-on:click.meta="openInNewTab"
              v-on:click.exact="openObjectDetailComponent"
              @click.prevent.stop
              :href="href">
            <strong>{{object.title}}</strong>
          </b-link>
        </span>
        <span class="block">
          <span v-if="year">{{year}} - </span>{{technique}}
        </span>
      </div>

    </b-link>

    <div
        v-if="inPreviewMode"
        v-on:click.ctrl="openInNewTab"
        v-on:click.meta="openInNewTab"
        v-on:click.exact="openObjectDetailComponent"
        :id="domId"
        class="inner-link object">

      <ObjectImage
          :url="imageUrl"
          :activationStatus="activationStatus"/>

      <div class="object-info">
        <span class="link block">
          <div
              v-on:click.ctrl="openInNewTab"
              v-on:click.meta="openInNewTab"
              v-on:click.exact.stop="openObjectDetailComponent">
            <strong class="inner-link">{{object.title}}</strong>
          </div>
        </span>
        <span class="block">
          <span v-if="year">{{year}} - </span>{{technique}}
        </span>
      </div>

    </div>

  </div>
</template>

<script>
import { getVueInstance } from '@/helpers/AppHelpers';
import { inMgnlIframe, inEditorEdit } from "../vue-editor";
import { BLink } from 'bootstrap-vue';
import ObjectImage from "./ObjectImage";

export default {
  name:'Object',
  props: ['object', 'index', 'category', 'categoryIndex', 'parentId'],
  components: {
    'b-link': BLink,
    ObjectImage
  },
  computed:{
    imageUrl (){
      return this.object.image;
    },
    year (){
      return this.object.year;
    },
    technique (){
      return this.object.technique;
    },
    domId (){
      return `object_${this.object.id}`;
    },
    href (){
      return `${location.origin}${location.pathname.replace('.html', '')}/object/${this.object.slug}`
    },
    activationStatus(){
      return this.object.activationStatus;
    },
    inPreviewMode(){
      return inMgnlIframe && !inEditorEdit;
    }
  },
  methods: {
    openObjectDetailComponent: function (){
      let elementDetailComponent = getVueInstance(this.parentId);

      let objectDetails = {
        updateHistory: true,
        category: this.category,
        index: this.index,
        clickedId: this.domId, // Used to restore scroll position on objects list
        ...this.object
      }
      elementDetailComponent.currentObject = objectDetails;
      elementDetailComponent.openObjectDetailComponent();
    },
    openInNewTab: function(){
      window.open(this.href, '_blank');
    }
  }
};
</script>
<style lang="scss" scoped>
@import 'src/vars.scss';

.object{
  padding:$spacer/2 !important;
  background-image: none !important;
  display:block;
  width:300px;

  span{
    max-width:100%;
    word-break: break-word;
    font-size:rem(20px);
  }

  .object-info{
    padding-top:rem(25px);
  }
}

@include media-breakpoint-down(sm){
  .object{
    padding:$spacer/4;
    width:100%;

    .object-info{
      padding-top:rem(10px);
    }
  }
}
</style>