<template>
  <div v-html="content" class="embed"></div>
</template>

<script>
export default {
  name: 'HTMLComponent',
  props: ['content', 'isResponsive'],
  mounted() {
    // Responsive resize
    if(!JSON.parse(this.isResponsive))
      return;

    // List of embeds selectors you want to support
    const players = ['iframe'];

    // Select iframe within component
    const fitVids = this.$el.querySelectorAll(players.join(","));

    // If there are iframes on the component...
    if (fitVids.length) {
      // Loop through iframes
      for (let i = 0; i < fitVids.length; i++) {
        // Get iframe information
        let fitVid = fitVids[i];
        let width = fitVid.getAttribute("width");
        let height = fitVid.getAttribute("height");
        let aspectRatio = height / width;
        let parentDiv = fitVid.parentNode;

        // Wrap it in a DIV
        let div = document.createElement("div");
        div.className = "fitVids-wrapper";
        div.style.paddingBottom = aspectRatio * 100 + "%";
        parentDiv.insertBefore(div, fitVid);
        fitVid.remove();
        div.appendChild(fitVid);

        // Clear height/width from fitVid
        fitVid.removeAttribute("height");
        fitVid.removeAttribute("width");
      }
    }
  }
};
</script>