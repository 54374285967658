<template>
  <div class="root-flipbook show-on-detail-mode">
    <b-row v-if="!flipbook_visible">

      <b-col sm="6" class="paddingBottom image-col" v-lazyload>
        <b-img
            class="lazy-img-fadein"
            :src="previewImageSrc.src"
            :srcset="previewImageSrc.srcset"
            :sizes="previewImageSrc.sizes"
            @error="noImage"
            fluid-grow />
      </b-col>

      <b-col sm="3" lg="4">
        <ul class="pdfsList" v-if="component">
          <li class="link cta" v-for="(item, key) in books" :key="key">
            <span class="icon sc-icon icon-arrow-right"></span>
            <span class="inner-link" @click="selectPdf(item)">{{item.language}}</span>
          </li>
        </ul>
      </b-col>

    </b-row>

    <FlipbookItem
            v-show="component & flipbook_visible"
            @closeFlipbook="closeFlipbook()"
            :flipbookData="currentBook" :key="componentKey"/>

  </div>
</template>

<script>
import FlipbookItem from './FlipbookItem';
import { BImg } from 'bootstrap-vue';
import {getVueInstance, getLazyLoadImage, hideContentForDetailMode, scrollToTop} from "../helpers/AppHelpers";

export default {
  name: "FlipbookComponent",
  props: ["previewImage", "flipbookData"],
  components: {
    FlipbookItem,
    'b-img': BImg,
  },
  data: function() {
    return {
      flipbook_visible: false,
      previewImageSrc: "",
      componentKey:0,
      currentBook: {}
    }
  },
  computed: {
    component: function() {
      if(this.flipbookData?.length > 0) {
        return true;
      } else  {
        return false;
      }
    },
    books: function(){
      let books = [];
      for(let item of this.flipbookData){
        if (item.hasOwnProperty('language'))
          books.push(item);
      }
      return books;
    }
  },
  methods: {
    selectPdf: function (item) {
      this.currentBook = item;
      this.flipbook_visible = true;
      this.componentKey += 1;

      let app = getVueInstance('app');
      app.addLayer();
      hideContentForDetailMode(app.detailMode);
      scrollToTop(this);
    },
    closeFlipbook() {
      this.flipbook_visible = false;
      let app = getVueInstance('app');
      app.removeLayer();
      hideContentForDetailMode(app.detailMode);
    },
    noImage(){
      // Get the default placeholder image
      this.previewImageSrc = getLazyLoadImage('');
    }
  },
  mounted: function() {
    this.previewImageSrc = getLazyLoadImage(this.previewImage);
  }
};
</script>

<style scoped lang="scss">
@import 'src/vars.scss';
ul {
  list-style: none;
  padding:0;
}

@include media-breakpoint-down(md){
  .root-flipbook .row{
    margin:-$spacer/2;
    margin-top:0;
    margin-bottom:0;

    .image-col{
      padding:0;
    }
  }
}
</style>