<template>
    <b-button
        :type="type"
        :variant="processedVariant"
        v-on:click="onClick"
        v-html="text"></b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: 'ButtonComponent',
  props: ['text', 'type', 'variant', 'onClick'],
  components:{
    'b-button': BButton
  },
  computed:{
    processedVariant: function(){
      switch (this.variant){
        case 'light':
          return 'primary';
        case 'dark':
          return 'secondary';
        case 'transparent':
          return 'link';
        default:
          return 'secondary';
      }
    }
  }
};
</script>

<style lang="scss">
@import 'src/vars.scss';

.btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  margin-right: .4rem;
  margin-top: $spacer;
  height: rem(57px);

  &.btn-primary{
    &:not(:disabled):not(.disabled){
      &:hover{
        background-color: $secondary;
        color: $primary;
      }

      &:focus{
        background-color: $secondary;
        color: $primary;
      }

      &:active{
        background-color: $secondary;
        color: $primary;
      }
    }
  }

  &.btn-link{
    &:not(:disabled):not(.disabled){
        @media (hover: hover) and (pointer: fine) {
            &:hover{
                color: rgba($black, .5);
            }
        }

      &:focus{
        color: rgba($black, .5);
        box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
      }

      &:active{
        color: rgba($black, .5);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .btn{
    margin-top: $spacer/2;
  }
}
</style>