<template>
  <div class="sidebar-section">
    <NavigationItem
      v-for="item in sidenavigation"
      :key="item['@id']"
      :item="item"
      :isHomePage="isHomePage"/>
  </div>

</template>

<script>
import NavigationItem from './NavigationItem';

export default {
  name: 'SidebarSection',
  props: ['title', 'sidenavigation', 'isHomePage'],
  components:{
    NavigationItem
  }
}
</script>