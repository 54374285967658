<template>
  <div :id="'object-detail' + id" class="object-detail-modal">
    <div class="object-detail-content">
      <div v-show="!imageIsOpen">

        <h1 class="headline"><strong v-if="artist">{{artist.name}}</strong>, {{title}}</h1>

        <Button
            :text="''"
            :variant="'transparent'"
            :onClick="closeObjectDetailComponent"
            class="close-button icon icon-close"/>

        <section>
          <b-row class="flex-column-reverse flex-md-row">
            <b-col md="6" class="object-info-col">

              <div
                  v-if="admincentralEditLink"
                  class="internal informations"
                  :style="{'border-color':borderColor}">
                <b-row>
                  <b-col sm="12" xl="8">
                    <b-row v-if="priceSale">
                      <b-col md="6"><strong>Preis CHF (Verkauf)</strong></b-col>
                      <b-col md="6">CHF {{priceSale}}</b-col>
                    </b-row>
                    <b-row v-if="pricePurchase">
                      <b-col md="6"><strong>Preis CHF (Einkauf)</strong></b-col>
                      <b-col md="6">CHF {{pricePurchase}}</b-col>
                    </b-row>
                    <b-row v-if="supplier">
                      <b-col md="6"><strong>Lieferant</strong></b-col>
                      <b-col md="6">{{supplier}}</b-col>
                    </b-row>
                    <b-row v-if="ownership">
                      <b-col md="6"><strong>Eigentümer</strong></b-col>
                      <b-col md="6">{{ownership}}</b-col>
                    </b-row>
                    <b-row v-if="purchaseDate">
                      <b-col md="6"><strong>Kauf Datum</strong></b-col>
                      <b-col md="6">{{purchaseDate}}</b-col>
                    </b-row>
                    <b-row v-if="salesOwner">
                      <b-col md="6"><strong>Verkauf-Besitzer</strong></b-col>
                      <b-col md="6">{{salesOwner}}</b-col>
                    </b-row>
                    <b-row v-if="salesDate">
                      <b-col md="6"><strong>Verkaufsdatum</strong></b-col>
                      <b-col md="6">{{salesDate}}</b-col>
                    </b-row>
                    <b-row v-if="categories">
                      <b-col md="6"><strong>Kategorien</strong></b-col>
                      <b-col md="6">{{categories}}</b-col>
                    </b-row>
                    <b-row v-if="tags">
                      <b-col md="6"><strong>Schlagwörter</strong></b-col>
                      <b-col md="6">{{tags}}</b-col>
                    </b-row>
                    <b-row v-if="notes">
                      <b-col md="6"><strong>Notiz</strong></b-col>
                      <b-col md="6" v-html="notes" class="notes"></b-col>
                    </b-row>
                    <b-row v-if="deleteError">
                      <b-col md="12">
                        <span class="text-danger error">Es ist ein Fehler aufgetreten und das Objekt konnte nicht gelöscht werden.</span>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col sm="12" xl="4" class="text-right">
                    <b-link class="magnolia hide-on-print" :href="admincentralEditLink" target="_blank">Bearbeiten</b-link>
                    <button class="magnolia red hide-on-print" @click="deleteObject">Löschen</button>
                  </b-col>
                </b-row>
              </div>

              <div class="informations">

                <div v-html="description" class="description"></div>

                <b-row v-if="year">
                  <b-col md="6" xl="4"><strong>Jahr</strong></b-col>
                  <b-col md="6" xl="8">{{year}}</b-col>
                </b-row>
                <b-row v-if="classification">
                  <b-col md="6" xl="4"><strong>Klassifikation</strong></b-col>
                  <b-col md="6" xl="8">{{classification}}</b-col>
                </b-row>
                <b-row v-if="technique">
                  <b-col md="6" xl="4"><strong>Technik</strong></b-col>
                  <b-col md="6" xl="8">{{technique}}</b-col>
                </b-row>
                <b-row v-if="edition">
                  <b-col md="6" xl="4"><strong>Auflage</strong></b-col>
                  <b-col md="6" xl="8">{{edition}}</b-col>
                </b-row>
                <b-row v-if="format">
                  <b-col md="6" xl="4"><strong>Format</strong></b-col>
                  <b-col md="6" xl="8">{{format}}</b-col>
                </b-row>
                <b-row v-if="exhibitionLocation">
                  <b-col md="6" xl="4"><strong>Austellungsort</strong></b-col>
                  <b-col md="6" xl="8">{{exhibitionLocation}}</b-col>
                </b-row>
                <b-row v-if="dateOfExhibition">
                  <b-col md="6" xl="4"><strong>Austellungsdatum</strong></b-col>
                  <b-col md="6" xl="8">{{dateOfExhibition}}</b-col>
                </b-row>
                <b-row v-if="publisher">
                  <b-col md="6" xl="4"><strong>Verleger</strong></b-col>
                  <b-col md="6" xl="8">{{publisher}}</b-col>
                </b-row>
                <b-row v-if="print">
                  <b-col md="6" xl="4"><strong>Druck</strong></b-col>
                  <b-col md="6" xl="8">{{print}}</b-col>
                </b-row>
                <b-row v-if="signed">
                  <b-col md="6" xl="4"><strong>Signiert</strong></b-col>
                  <b-col md="6" xl="8">Ja<span v-if="signedNote">, {{signedNote}}</span></b-col>
                </b-row>
                <b-row v-if="catalogue">
                  <b-col md="6" xl="4"><strong>Werkverzeichnis</strong></b-col>
                  <b-col md="6" xl="8">{{catalogue}}</b-col>
                </b-row>
                <b-row v-if="price && priceVisibility">
                  <b-col md="6" xl="4"><strong>Preis</strong></b-col>
                  <b-col md="6" xl="8">CHF {{price}}</b-col>
                </b-row>

                <Button
                    v-if="requestLink"
                    :text="'<span class=\'icon icon-arrow-right\'></span> <a>Anfrage zum Werk</a> '"
                    :variant="'transparent'"
                    class="text-button no-margin hide-on-print"
                    :onClick="openForm"/>

              </div>
            </b-col>
            <b-col md="6" class="full-width object-image-col">
              <div v-lazyload>
                <b-img
                    id="objectImage"
                    class="lazy-img-fadein image"
                    :class="{'forced-loaded': sameImage}"
                    :data-url="lazyImage.src"
                    :src="lazyImage.src"
                    :srcset="lazyImage.srcset"
                    :sizes="lazyImage.sizes"
                    v-on:click="openObjectDetailImage"
                    fluid-grow />
              </div>
            </b-col>
          </b-row>
        </section>

      </div>

      <ObjectDetailImage
          :isOpen="imageIsOpen"
          :url="image"
          :artist="artist"
          :title="title"
          :objectId="id"/>

    </div>

    <FormComponent ref="form" :objectId="this.id"/>

    <b-row
        v-if="!imageIsOpen"
        class="navigation-buttons no-gutters" >

      <b-col cols="6">
        <Button
            v-if="isPrevObject"
            :text="'<span class=\'icon icon-arrow-left\'></span><a>Vorheriges</a> '"
            :variant="'transparent'"
            :onClick="prevObject"
            class="no-margin hide-on-print"/>
      </b-col>

      <b-col cols="6" class="d-flex justify-content-end">
        <Button
            v-if="isNextObject"
            :text="'<a>Nächstes</a> <span class=\'icon icon-arrow-right\'></span>'"
            :variant="'transparent'"
            :onClick="nextObject"
            class="no-margin hide-on-print"/>
      </b-col>
    </b-row>

  </div>
</template>

<script>
  import { BImg } from 'bootstrap-vue';
  import { BLink } from 'bootstrap-vue';
  import Button from "./Button";
  import ObjectDetailImage from "./ObjectDetailImage";
  import FormComponent from "./Form";
  import {
    getVueInstance,
    getLazyLoadImage,
    activateTermLinks,
    formatNumber,
    activateObjectLinks
  } from "../helpers/AppHelpers";
  export default {
    name:'ObjectDetail',
    props: ['object', 'parentId', 'eventShowPrice'],
    components:{
      Button,
      ObjectDetailImage,
      FormComponent,
      'b-img': BImg,
      'b-link': BLink
    },
    data () {
      return {
        imageIsOpen: false, // Used to toggle the zoomed in image modal
        deleteError: false
      }
    },
    computed: {
      id (){
        return this.object.id;
      },
      artist () {
        return this.object.artist;
      },
      title () {
        return this.object.title;
      },
      image (){
        return this.object.image;
      },
      // Use "imageUrl" instead of "image" for testing with npm run serve
      imageUrl (){
        if(location.href.includes('localhost:8081'))
          return 'http://localhost:8081/images/image2.png';
        else
          return this.image;
      },
      lazyImage(){
        return getLazyLoadImage(this.image);
      },
      sameImage (){
        return this.object.sameImage;
      },
      technique (){
        return this.object.technique;
      },
      format (){
        return this.object.format;
      },
      year (){
        return this.object.year;
      },
      classification (){
        return this.object.classification;
      },
      edition (){
        return this.object.edition;
      },
      exhibitionLocation (){
        return this.object.exhibitionLocation;
      },
      dateOfExhibition (){
        return this.object.dateOfExhibition;
      },
      publisher (){
        return this.object.publisher;
      },
      print (){
        return this.object.print;
      },
      catalogue (){
        return this.object.catalogue;
      },
      priceSale (){
        return formatNumber(this.object.priceSale);
      },
      pricePurchase (){
        return formatNumber(this.object.pricePurchase);
      },
      price (){
        return this.priceSale;
      },
      priceVisibility(){
        let eventVisibility = true;
        if(this.parentId === 'event-detail' && this.eventShowPrice === false)
          eventVisibility = false;

        return JSON.parse(this.object.priceVisibility) && eventVisibility;
      },
      description (){
        return this.object.description;
      },
      supplier (){
        return this.object.supplier?.name;
      },
      ownership (){
        return this.object.ownership ? 'Ja' : 'Nein';
      },
      purchaseDate (){
        return this.object.purchaseDate ? this.getFormattedDate(new Date(this.object.purchaseDate)) : false;
      },
      salesDate (){
        return this.object.salesDate ? this.getFormattedDate(new Date(this.object.salesDate)) : false;
      },
      salesOwner (){
        return this.object.salesOwner?.name;
      },
      notes (){
        return this.object.notes;
      },
      categories (){
        return this.object.categories?.join(', ');
      },
      tags (){
        return this.object.tags?.replaceAll(',', ', ');
      },
      admincentralEditLink (){
        return this.object.admincentralEditLink;
      },
      requestLink(){
        return this.object.requestlink ? JSON.parse(this.object.requestlink) : true;
      },
      signed(){
        return this.object.signed ? JSON.parse(this.object.signed) : false;
      },
      signedNote(){
        return this.object.signedNote;
      },
      isPrevObject (){
        // Check if there is a previous object in the collection
        let currentObjectIndex = this.object.index;
        let prevObjectIndex = currentObjectIndex - 1;

        return prevObjectIndex >= 0;
      },
      isNextObject (){
        // Check if there is a next object in the collection
        let currentObjectIndex = this.object.index;
        let nextObjectIndex = currentObjectIndex + 1;

        return this.object.category ? nextObjectIndex < this.object.category.objects.length : false;
      },
      borderColor(){
        const activationColors = ['#F54200','#FFB300', '#599900', '#F2F2F2'];

        if(this.object.activationStatus !== undefined)
          return activationColors[this.object.activationStatus];
        else
          return activationColors[3];
      }
    },
    watch:{
      id(){
        this.deleteError = false; // Reset deleteError flag when the object changes
      }
    },
    methods:{
      // Closes the modal notifying the parent Artist Detail component as well
      closeObjectDetailComponent: function (scrollToElement = true){
        const clickedId = scrollToElement ? this.object.clickedId : '';

        let elementDetailComponent = getVueInstance(this.parentId);

        elementDetailComponent.closeObjectDetailComponent(clickedId);

        this.$refs.form?.cancel();
      },
      // Go to the next object
      nextObject: function(){
        let nextObjectIndex = this.object.index + 1;

        if(this.isNextObject){
          document.querySelector('#objectImage').classList.remove('loaded'); // Remove 'loaded class' from previous image loaded by Lazy Load

          // Set the "current object" of the parent Artist Detail component to
          // the next object in the collection
          let elementDetailComponent = getVueInstance(this.parentId);

          elementDetailComponent.currentObject = {
            updateHistory: true,
            category: this.object.category,
            index: nextObjectIndex,
            clickedId: this.object.clickedId, // Used to restore scroll position on objects list
            sameImage: this.checkIsSameImage(this.object.category.objects[nextObjectIndex]),
            ...this.object.category.objects[nextObjectIndex]
          };
        }

        this.$refs.form?.cancel();
        document.activeElement.blur(); // Remove focus from button
        activateTermLinks();
        activateObjectLinks();
        setTimeout(() => {document.getElementById('object-detail' + this.object.id).scrollIntoView({behavior: "smooth"})}, 0);
      },
      prevObject: function(){
        document.querySelector('#objectImage').classList.remove('loaded'); // Remove 'loaded class' from previous image loaded by Lazy Load

        let prevObjectIndex = this.object.index - 1;

        if(this.isPrevObject){
          let elementDetailComponent = getVueInstance(this.parentId);

          // Set the "current object" of the parent Artist Detail component to
          // the previous object in the collection
          elementDetailComponent.currentObject = {
            updateHistory: true,
            category: this.object.category,
            index: prevObjectIndex,
            clickedId: this.object.clickedId, // Used to restore scroll position on objects list
            sameImage: this.checkIsSameImage(this.object.category.objects[prevObjectIndex]),
            ...this.object.category.objects[prevObjectIndex]
          };
        }

        this.$refs.form?.cancel();
        document.activeElement.blur(); // Remove focus from button
        activateTermLinks();
        activateObjectLinks();
        setTimeout(() => {document.getElementById('object-detail' + this.object.id).scrollIntoView({behavior: "smooth"})}, 0);
      },
      openObjectDetailImage: function(){
        this.imageIsOpen = true;
      },
      closeObjectDetailImage: function(){
        this.imageIsOpen = false;
      },
      getFormattedDate: function(date){
        return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
      },
      checkIsSameImage(object){
        return object.image === this.image;
      },
      openForm(){
        document.activeElement.blur(); // Remove focus from button
        this.$refs.form?.openForm();
      },
      async deleteObject(){
        if(this.admincentralEditLink && window.confirm("Wollen Sie das Objekt wirklich löschen?")){

          // Delete object
          fetch(`${process.env.VUE_APP_HOST}/.rest/gallery/v1/object/slug?slug=${this.object.slug}`, {
            method: 'DELETE',
          })
          .then((response)=>{
            // Success handling
            if(response.status === 200){
              // Go back to the list view
              history.back();

              // For the Objects List in expanded view mode, manually trigger a refresh
              const parentArtistDetail = getVueInstance(this.parentId);
              const parentObjectsList = getVueInstance(parentArtistDetail.parentId);

              if(parentObjectsList.expandedList){
                parentObjectsList.loadFromEndpoint();
              }
            }
            // Failure handling
            else {
              throw Error(response.statusText);
            }
          }).catch(() => {
            this.deleteError = true; // show error
          });
        }
      }
    }
  };
</script>
<style lang="scss">
  @import 'src/vars.scss';
  .object-detail-modal{

    .object-detail-content{
      position:relative;
      background-color: $custom-grey;
      margin:-$spacer;
      margin-bottom:0;
      padding:$spacer;

      .headline{
        width:calc(100% - 2.5rem);
      }

      .close-button{
        height:1rem + rem(30px);
        position:absolute;
        right:$spacer;
        top:$spacer - rem(10px);
        padding:0;
        margin:0;
      }

      .image{
        margin-top: $spacer/2;
        margin-bottom:$spacer/2;
        cursor: pointer;
      }

      .description{
        h4{
          font-weight:bold;
        }

        padding-bottom:$spacer/2;
      }

      .informations{
        margin-top: $spacer/2;

        .row{
          padding-bottom:$spacer/4;
        }

        .text-button{
          display:flex;
          padding:0;
          margin-top:$spacer;
          margin-bottom:$spacer/2;
          height:rem(20px);

          .icon-arrow-right{
            margin-right:$spacer/2;
          }
        }

        &.internal{
          background-color:$green;
          padding:$spacer/2;
          padding-bottom:0;
          margin-bottom:$spacer;
          border:2px solid $custom-grey;

          .magnolia{
            background-color:#599900;
            background-image:none;
            opacity:1;
            color:white;
            padding:5px 20px;
            border-radius:50px;
            display:inline-block;
            margin-bottom:$spacer/4;
            margin-left:$spacer/4;
            border:none;

            &.red{
              background-color: #F54200;
              cursor:pointer;
            }

            &:focus{
              outline:0;
              box-shadow: 0 0 0 1px rgb(16,16,16);
            }
          }

          .notes p:last-child{
            padding-bottom:0;
          }
        }
      }
    }

    .navigation-buttons{

      button{
        display:flex;
        padding:0;
        margin-top:$spacer;
        height:rem(20px);

        .icon-arrow-left{
          margin-right:$spacer/2;
        }

        .icon-arrow-right{
          margin-left:$spacer/2;
        }
      }
    }
  }

  .paddingTop .object-detail-modal{
    margin-top: -$spacer;
  }

  .paddingBottom .object-detail-modal{
    margin-bottom: -$spacer;
  }

  .paddingLeft .object-detail-modal{
    margin-left: -$spacer;
  }

  .paddingRight .object-detail-modal{
    margin-right: -$spacer;
  }

  @include media-breakpoint-down(lg){
    .informations{
      &.internal{

        a.magnolia{
          margin-top:$spacer/4;
        }
      }
    }
  }

  @include media-breakpoint-down(md){
    .object-detail-modal{

      .object-detail-content{
        margin:-$spacer/2;
        margin-bottom:0;
        padding:$spacer/2;

        .row .full-width{
          padding: 0px;
          margin-left: -5px;
          width: calc(100% + 10px);
        }

        .close-button{
          position:absolute;
          padding:15px;
          right:$spacer/2 - rem(15px);
          top:-$spacer/2 - rem(48px);
        }

        .image{
          margin-top: 0;
        }

        .description{
          padding-bottom:$spacer/4;
        }

        .informations{
          button{
            margin-top:$spacer/2;
          }

          &.internal{
            margin-top:0;

            a.magnolia{
              margin-top:$spacer/4;
            }
          }
        }
      }

      .navigation-buttons{
        button{
          margin-top:$spacer/2;

          .icon-arrow-left{
            margin-right:$spacer/4;
          }

          .icon-arrow-right{
            margin-left:$spacer/4;
          }
        }
      }
    }

    .paddingTop .object-detail-modal{
      margin-top: -$spacer/2;

      .object-detail-content{
        .close-button{
          top:-$spacer - rem(48px);
        }
      }
    }

    .paddingBottom .object-detail-modal{
      margin-bottom: -$spacer/2;
    }

    .paddingLeft .object-detail-modal{
      margin-left: -$spacer/2;
    }

    .paddingRight .object-detail-modal{
      margin-right: -$spacer/2;
    }
  }
</style>