<template>
  <div
    :class="{paddingTop: parsedPaddingTop, paddingBottom: parsedPaddingBottom,paddingLeft: parsedPaddingLeft, paddingRight: parsedPaddingRight}">
    <EditableArea v-if="container" :content="container"></EditableArea>
  </div>
</template>

<script>
import { EditableArea } from '../vue-editor';

export default {
  name: 'Container',
  components: {
      EditableArea
  },
  props: ['container', 'paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight'],
  computed:{
    parsedPaddingTop: function(){
      return this.paddingTop === 'true';
    },
    parsedPaddingBottom: function(){
      return this.paddingBottom === 'true';
    },
    parsedPaddingLeft: function(){
      return this.paddingLeft === 'true';
    },
    parsedPaddingRight: function(){
      return this.paddingRight === 'true';
    }
  }
};
</script>