<template>
  <div :id="domId" :class="{'show-on-detail-mode': detailMode, paddingTop: paddingTop, paddingBottom: paddingBottom, paddingLeft: paddingLeft, paddingRight: paddingRight}">

    <transition name="fade">
      <section v-show="mode === 'list' && listMode === 'list'">
        <h1 v-show="!detailMode"><b>{{headline}}</b></h1>
        <b-row>
          <ObjectsListColumn
              v-for="col in cols"
              :key="col.n"
              :elements="col.elements"
              :parentId="domId"/>

        </b-row>
      </section>
    </transition>

    <h1 v-show="!detailMode && mode !== 'elementDetail' && listMode === 'expandedList'"><b>{{headline}}</b></h1>
    <ArtistDetail
        v-show="mode === 'elementDetail' || mode === 'objectDetail' || listMode === 'expandedList'"
        :artistBasicInfo="currentElement"
        :groupByCategory="groupByCategory"
        :categoriesFilter="categoriesForQuery"
        :tagsFilter="tagsForQuery"
        :mode="mode"
        :listMode="listMode"
        :parentDetailMode="detailMode"
        :parentId="domId"
        ref="artistDetail"/>

  </div>
</template>

<script>
import ObjectsListColumn from './ObjectsListColumn';
import ArtistDetail from './ArtistDetail';
import { EventBus } from '../main';

export default {
  name: "ObjectsList",
  props: ["headline", "artists", "categories", "tags", "groupByCategory", "expandedList", "spacing"],
  components: {
    ObjectsListColumn,
    ArtistDetail
  },
  data () {
    return {
      elements: [],
      cols: [],
      mode: 'list',
      currentElement: {},
      detailMode: false
    }
  },
  computed: {
    artistsForQuery: function (){
      return this.artists.filter(artist => artist !== '').join(',');
    },
    categoriesForQuery: function (){
      return this.categories.filter(category => category !== '').join(',');
    },
    tagsForQuery: function (){
      return this.tags.filter(tag => tag !== '').join(',');
    },
    listMode: function (){
      return this.expandedList === "true" ? 'expandedList' : 'list';
    },
    domId (){
      // Generate a unique ID since there might be more than one ObjectsList component on the page
      return `objects-list-${Math.random().toString(36).substr(2, 9)}`;
    },
    paddingTop (){
      return this.spacing && JSON.parse(this.spacing.paddingTop);
    },
    paddingBottom(){
      return this.spacing && JSON.parse(this.spacing.paddingBottom);
    },
    paddingLeft (){
      return this.spacing && JSON.parse(this.spacing.paddingLeft);
    },
    paddingRight (){
      return this.spacing && JSON.parse(this.spacing.paddingRight);
    }
  },
  watch:{
    elements: function(elements){
      if(this.listMode === 'expandedList'){
        this.currentElement = {
          artist: {
            name: 'Collection'
          },
          slug: 'collection',
          data: elements
        }
      }
      else
        this.splitElementsIntoColumns(elements);
    },
    currentElement: function(object){
      if(object.updateHistory)
        history.pushState(null, null, `${location.origin}${location.pathname.replace('.html', '')}?detail=${object.slug}`);

      this.$nextTick(function () {
        this.$refs.artistDetail.getPageContentFromParameters();
      });
    }
  },
  async created(){

    this.setMode();

    await this.loadFromEndpoint();

    this.registerPopState();

    EventBus.$on('simple-overlay-opened', this.unregisterPopState);
    EventBus.$on('all-simple-overlays-closed', this.registerPopState);
  },
  methods:{
    loadFromEndpoint: async function(){
      let api = `${process.env.VUE_APP_HOST}/.rest/gallery/v1/list`;

      // Populate the query with the parameters
      let parameters = [];

      if (this.artistsForQuery)
        parameters.push(`artists=${this.artistsForQuery}`);

      if (this.categoriesForQuery)
        parameters.push(`categories=${this.categoriesForQuery}`);

      if (this.tagsForQuery)
        parameters.push(`tags=${this.tagsForQuery}`);

      if (this.groupByCategory)
        parameters.push(`groupByCategory=${this.groupByCategory}`);

      if (this.expandedList)
        parameters.push(`displayObjects=${this.expandedList}`);

      if(parameters)
        parameters = `?${parameters.join('&')}`;

      // Run the query
      let listResponse = await fetch(api + parameters);

      let list = await listResponse.json();

      this.elements = list.data;
    },
    // Split the elements in the list into multiple columns, taking into account potential remainder division
    splitElementsIntoColumns: function(elements){
      let n_of_columns = 4;
      let n_of_elements = elements.length;
      let offset = 0;

      // Use an offset to reach a number of elements that can be divided equally and then remove it from the last column
      while(n_of_elements % n_of_columns !== 0){
        offset++;
        n_of_elements = n_of_elements + offset;
      }

      let elements_per_column = n_of_elements / n_of_columns;

      this.cols = [];

      if(elements_per_column > 1) {
        this.cols.push({ n: 1, elements: elements.slice(0, elements_per_column)});
        this.cols.push({ n: 2, elements: elements.slice(elements_per_column, (elements_per_column * 2))});
        this.cols.push({ n: 3, elements: elements.slice((elements_per_column * 2), (elements_per_column * 3))});
        this.cols.push({ n: 4, elements: elements.slice((elements_per_column * 3), (elements_per_column * 4) - offset)});
      } else {

        for(let elementIndex in elements){
          const element = elements[elementIndex];
          this.cols.push({ n: elementIndex, elements: [element]});
        }
      }
    },
    // Opens the Element Detail modal
    openElementDetailComponent: function(){
      this.mode = 'elementDetail';
    },
    onPopState: function(){
      this.setMode();

      this.$refs.artistDetail ? this.$refs.artistDetail.onPopState() : false;
    },
    setMode: function(){
      let url = decodeURIComponent(location.href);

      let mode = 'list'

      if(url.includes('/object/') || url.includes('object='))
        mode = 'objectDetail'
      else if(url.includes('/detail/') || url.includes('detail='))
        mode = 'elementDetail'

      this.mode = mode;

      return mode;
    },
    registerPopState: function(){
      window.addEventListener('popstate', this.onPopState);
    },
    unregisterPopState: function(){
      window.removeEventListener('popstate', this.onPopState);
    }
  }
};
</script>