<template>
  <div class="term-link-wrapper">
    <a @click.prevent="openDetail"
      class="no-background-image">
      <span class="inner-link" v-html="elementHTML"></span>
    </a>
    <TermDetail ref="detail" v-show="term" :term="term"/>
  </div>
</template>

<script>
import TermDetail from "./TermDetail";
export default {
  name: 'TermLink',
  props: ['domElement'],
  components:{
    TermDetail
  },
  data(){
    return {
      term: ''
    }
  },
  computed: {
    elementHTML(){
      return this.domElement.innerHTML;
    }
  },
  methods:{
    async openDetail(){
      // If the term is already in memory, don't call the endpoint
      if(this.term === ''){
        const termResponse = await fetch(
          process.env.VUE_APP_HOST + '/.rest/delivery/terms' + this.getTermPath()
        );

        const term = await termResponse.json();

        this.term = term;
      }

      this.$refs.detail.open();
    },
    getTermPath(){
      return decodeURI(this.domElement.href).split('path:{')[1].replaceAll('}', '');
    }
  }
};
</script>

<style scoped lang="scss">
  @import 'src/vars.scss';
  .term-link-wrapper{
    display:inline;
  }
</style>